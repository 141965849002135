import {assert} from 'wnd-util';
import {AuthState, ValidAuthState, ValidAuthStateWithUser} from '>store/reducers/authReducer';

export function assertAuthenticatedAuthState(
  authState: AuthState
): asserts authState is ValidAuthStateWithUser {
  assert.assert(authState.isValidToken === true, 'No Valid Token Found');
  assert.assert(authState.isAuthenticated, 'Authentication Required');
  assert.assertExists(authState.token?.user, 'No Authenticated User');
}

export function assertAuthStateContainsValidToken(
  authState: AuthState
): asserts authState is ValidAuthState {
  assert.assert(authState.isValidToken === true, 'No Valid Token Found');
}
