import {tokenUser} from '>generated/auth.types';
import {BrowserInformation} from 'wnd-react-utils/lib/browser';
import {httpClient} from 'wnd-util';
import {Api} from './../generated/auth';

export class EnhancedAPI extends Api {
  protected enhancedClient = httpClient.create();

  private enhancedRootURL = '';

  constructor(rootUrl: string, enhancedRootUrl: string) {
    super(rootUrl);
    this.enhancedRootURL = enhancedRootUrl;
  }

  setAccessToken(accessToken: string) {
    super.setAccessToken(accessToken);
    this.enhancedClient.setAuthToken(accessToken);
  }

  clearAccessToken() {
    super.clearAccessToken();
    this.enhancedClient.clearAuthToken();
  }

  async logout(): Promise<httpClient.Response<void>> {
    const queryObj = {};
    const body = {};

    return await this.enhancedClient.request({
      method: 'post',
      url: `${this.enhancedRootURL}/logout`,
      queryParams: queryObj,
      body,
    });
  }

  async authenticateViaPassword(
    email: string,
    password: string,
    browserData: BrowserInformation
  ): Promise<httpClient.Response<tokenUser & void>> {
    const queryObj = {};
    const body = {
      email,
      password,
      browserData,
    };

    return this.enhancedClient.request({
      withCredentials: true,
      method: 'post',
      url: `${this.enhancedRootURL}/authenticate`,
      queryParams: queryObj,
      body,
    });
  }

  async createPassword(password: string): Promise<httpClient.Response<void>> {
    const queryObj = {};
    const body = {
      password,
    };
    return this.enhancedClient.request({
      method: 'post',
      url: `${this.enhancedRootURL}/create-password`,
      queryParams: queryObj,
      body,
    });
  }

  async changePassword(oldPassword: string, password: string): Promise<httpClient.Response<void>> {
    const queryObj = {};
    const body = {
      oldPassword,
      password,
    };
    return this.enhancedClient.request({
      withCredentials: true,
      method: 'post',
      url: `${this.enhancedRootURL}/change-password`,
      queryParams: queryObj,
      body,
    });
  }

  async updateInvitedUser(
    firstName: string,
    lastName: string,
    jobTitle: string,
    password: string,
    invitationCode: string | null,
    redirect?: string
  ): Promise<httpClient.Response<{redirect: string}>> {
    const queryObj = {};
    const body = {
      invitationCode,
      firstName,
      lastName,
      jobTitle,
      password,
      redirect,
    };
    return this.enhancedClient.request({
      method: 'post',
      url: `${this.enhancedRootURL}/complete-invitation`,
      queryParams: queryObj,
      body,
    });
  }
}
