import * as React from 'react';
import {getBrowserInformation} from 'wnd-react-utils/lib/browser';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {RouteChildrenProps} from 'react-router-dom';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {Button, ButtonSizes} from '>shared/components/button/button';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {Link} from '>shared/components/actions/link';
import {vr4, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {CardHeader, FlexCard, InputFields, Logo, StyledForm} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {LoginForm} from '>components/login/loginForm';
import {LoginFormFields} from '>components/login/loginFormFields';
import {authApi} from '>root/apis';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {getLoginRedirectRoute} from '>root/utils/loginRedirection';
import {LoginQueryParams} from '>root/types/enums';
import {pageChangeEffect} from '>lib/sideEffects';

interface BannerMessage {
  text: string;
  type: BannerType;
}

export const LoginPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('login');
  React.useEffect(pageChangeEffect(t('login:pageTitle')));

  const [bannerMessage, setBannerMessage] = React.useState<BannerMessage | undefined>(undefined);
  const formMethods = useForm<LoginFormFields>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors: missingInputErrors} = formMethods;

  const getBannerMessageFromQueryParams = React.useCallback((message: string):
    | BannerMessage
    | undefined => {
    switch (message) {
      case LoginQueryParams.EmailChangeExpired:
        return {
          text: t('changeEmailVerification:expiredError'),
          type: BannerType.Error,
        };
      case LoginQueryParams.InvalidHash:
        return {
          text: t('login:expiredHashMessage'),
          type: BannerType.Error,
        };
      case LoginQueryParams.PasswordChanged:
        return {
          text: t('resetPassword:successMessage'),
          type: BannerType.Success,
        };
      case LoginQueryParams.VerificationFailed:
        return {
          text: t('common:error.default.message'),
          type: BannerType.Error,
        };
      default:
        return undefined;
    }
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const message = queryParams.get('message');

    if (message) {
      const bannerMessage = getBannerMessageFromQueryParams(message);

      setBannerMessage(bannerMessage);
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(missingInputErrors).length) {
      setBannerMessage({
        text: t('login:emptyFieldsMessage'),
        type: BannerType.Error,
      });
    }
  }, [missingInputErrors]);

  const onSubmit = React.useCallback(async (data: LoginFormFields) => {
    try {
      const browserData = getBrowserInformation();
      const userResponse = await authApi.authenticateViaPassword(
        data.email.trim(),
        data.password,
        browserData
      );

      const userData = userResponse.data;

      const queryParams = new URLSearchParams(window.location.search);
      const redirectUriParam = queryParams.get('redirect_uri');

      window.location.replace(getLoginRedirectRoute(userData, redirectUriParam));
    } catch (error) {
      setBannerMessage({
        text: t('login:errorMessage'),
        type: BannerType.Error,
      });
    }
  }, []);

  const handleEmailChange = React.useCallback((newValue: string) => {
    setBannerMessage(undefined);
    formMethods.setValue('email', newValue);
  }, []);

  const handlePasswordChange = React.useCallback((newValue: string) => {
    setBannerMessage(undefined);
    formMethods.setValue('password', newValue);
  }, []);

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>Wonderlic</h1>
          {bannerMessage ? (
            <InfoBanner bannerType={bannerMessage.type} message={bannerMessage.text} />
          ) : (
            <LightParagraphText>{t('login:instructions')}</LightParagraphText>
          )}
        </CardHeader>
        <StyledForm onSubmit={handleSubmit(onSubmit)} css={vr6}>
          <InputFields>
            <LoginForm
              formMethods={formMethods}
              onEmailChange={handleEmailChange}
              onPasswordChange={handlePasswordChange}
            />
          </InputFields>
          <Button data-qa-button="sign-in-button" fullWidth size={ButtonSizes.LG} type="submit">
            {t('login:primaryButtonText')}
          </Button>
        </StyledForm>
        <Link css={{alignSelf: 'center'}} to={findPathFromLabel(RouteLabel.ForgotPassword)}>
          {t('login:linkText')}
        </Link>
      </FlexCard>
    </CenteredPageContents>
  );
};
