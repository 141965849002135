import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import styled from '@emotion/styled';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {StyledForm} from '>components/flexCard';

import {
  UserCreationFormFields,
  UserCreationFormFieldTypes,
} from './userCreationFormFields';

export interface ExistingUserData {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
}

interface Props {
  buttonText: string;
  onSubmit: SubmitHandler<UserCreationFormFieldTypes>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  userData?: ExistingUserData
}

const StyledButton = styled(Button)`
  margin-top: 2.4rem;
`;

export const UserCreationForm: React.FC<Props> = ({buttonText, onSubmit, setErrorMessage, userData}) => {
  const {t} = useTranslation('userCreation');
  const formMethods = useForm<UserCreationFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors} = formMethods;

  const clearErrorMessage = React.useCallback(() => setErrorMessage(''), []);
  React.useEffect(() => {
    if (errors.firstName) {
      setErrorMessage(t('userCreation:firstNameErrorMessage'));
    } else if (errors.lastName) {
      setErrorMessage(t('userCreation:lastNameErrorMessage'));
    } else if (errors.jobTitle) {
      setErrorMessage(t('userCreation:jobTitleErrorMessage'));
    } else if (errors.newPassword) {
      setErrorMessage(t('userCreation:formatErrorMessage'));
    } else if (errors.newPasswordMatch) {
      setErrorMessage(t('userCreation:matchingErrorMessage'));
    } 
  }, [errors]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <UserCreationFormFields
          onInputChange={clearErrorMessage}
          userData={userData}
        />
        <StyledButton data-qa-button="create-user-button" fullWidth size={ButtonSizes.LG} type="submit">
          {buttonText}
        </StyledButton>
      </StyledForm>
    </FormProvider>
  );
};
