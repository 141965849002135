import * as React from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {vr4, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {authApi} from '>root/apis';
import {BackLink} from '>components/backLink';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {LoginQueryParams} from '>root/types/enums';
import {pageChangeEffect} from '>lib/sideEffects';
import {PasswordCreationForm} from '>components/passwordCreation/passwordCreationForm';
import {rollbarLogger} from '>lib/logger';

export const ResetPasswordPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('resetPassword');
  React.useEffect(pageChangeEffect(t('resetPassword:pageTitle')));

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = React.useCallback(async (data) => {
    try {
      await authApi.createPassword(data.newPassword);

      location.replace(
        `${findPathFromLabel(RouteLabel.Login)}?message=${LoginQueryParams.PasswordChanged}`
      );
    } catch (error) {
      rollbarLogger.error(error as Error);
    }
  }, []);

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('resetPassword:headingText')}</h1>
          {errorMessage ? (
            <InfoBanner bannerType={BannerType.Error} message={errorMessage} />
          ) : (
            <LightParagraphText>{t('resetPassword:instructions')}</LightParagraphText>
          )}
        </CardHeader>
        <PasswordCreationForm
          css={vr6}
          buttonText={t('resetPassword:primaryButtonText')}
          setErrorMessage={setErrorMessage}
          onSubmit={onSubmit}
        />
        <BackLink
          href={findPathFromLabel(RouteLabel.Login)}
          linkText={t('resetPassword:backLinkText')}
        />
      </FlexCard>
    </CenteredPageContents>
  );
};
