import * as React from 'react';
import {HttpError} from 'wnd-util';
import {RouteChildrenProps} from 'react-router-dom';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {
  displayXsSemibold,
  textMdSemibold,
} from '>shared/components/typography/designSystemTypography';
import {gray500} from 'wnd-themes/lib/colorPalette';
import {vr4, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {assertAuthenticatedAuthState} from '>lib/assert';
import {authApi} from '>root/apis';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {ChangeEmailVerificationForm} from '>components/changeEmail/verify/changeEmailVerificationForm';
import {ChangeEmailVerificationFormFieldTypes} from '>components/changeEmail/verify/changeEmailVerificationFormFields';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {LoginQueryParams, ProfilePageQueryParams} from '>root/types/enums';
import {pageChangeEffect} from '>lib/sideEffects';
import {PROFILE_URL} from '>root/env';
import {rollbarLogger} from '>lib/logger';

export const ChangeEmailVerificationPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('changeEmailVerification');
  React.useEffect(pageChangeEffect(t('changeEmailVerification:pageTitle')));

  const authState = useSelector((state) => state.auth);

  assertAuthenticatedAuthState(authState);
  const user = authState.token.user;

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<string>('');

  const redirectToLogin = React.useCallback((message: LoginQueryParams) => {
    location.assign(`${findPathFromLabel(RouteLabel.Login)}?message=${message}`);
  }, []);

  const resendCode = React.useCallback(async () => {
    setErrorMessage('');

    try {
      await authApi.sendNewAuthCodeForPendingEmailChange({userId: user.id});
      setSuccessMessage(t('changeEmailVerification:successMessage'));
    } catch (err) {
      const httpError = err as HttpError;

      if (httpError.status === 400) {
        redirectToLogin(LoginQueryParams.EmailChangeExpired);
      } else {
        setErrorMessage(t('changeEmailVerification:genericErrorMessage'));
        rollbarLogger.error(err as Error);
      }
    }
  }, []);

  const onSubmit = React.useCallback(async (data: ChangeEmailVerificationFormFieldTypes) => {
    try {
      await authApi.verifyUserEmailChange({authCode: data.authCode}, {userId: user.id});
      location.assign(`${PROFILE_URL}?message=${ProfilePageQueryParams.EmailChanged}`);
    } catch (err) {
      const httpError = err as HttpError;

      if (httpError.status === 400) {
        //@ts-ignore
        if (httpError.payload.message.includes('PendingEmailChangeExpiredError')) {
          redirectToLogin(LoginQueryParams.EmailChangeExpired);
        } else {
          setErrorMessage(t('changeEmailVerification:invalidCodeErrorMessage'));
        }
      } else {
        setErrorMessage(t('changeEmailVerification:genericErrorMessage'));
        rollbarLogger.error(err as Error);
      }
    }
  }, []);

  const shouldRenderBanner = Boolean(errorMessage) || Boolean(successMessage);

  const queryParams = new URLSearchParams(location.search);
  const newEmail = queryParams.get('newEmail');

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('changeEmailVerification:headingText')}</h1>
          {shouldRenderBanner && (
            <InfoBanner
              css={vr4}
              bannerType={errorMessage ? BannerType.Error : BannerType.Info}
              message={errorMessage || successMessage}
            />
          )}
          <LightParagraphText>
            {`${t('changeEmailVerification:instructions')}: `}
            <BoldText>{newEmail}</BoldText>
          </LightParagraphText>
        </CardHeader>
        <ChangeEmailVerificationForm
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          onResend={resendCode}
          onSubmit={onSubmit}
        />
      </FlexCard>
    </CenteredPageContents>
  );
};

const BoldText = styled.span`
  ${textMdSemibold};
  color: ${gray500};
`;
