import * as React from 'react';
import styled from '@emotion/styled';

import {arrowBackIcon} from '>shared/components/icon/icons';
import {center} from '>shared/styles/mixins/alignment';
import {Icon} from '>shared/components/icon/icon';
import {Link} from '>shared/components/actions/link';
import {LinkVariant} from '>shared/components/actions/linkButton';
import {gray500, gray600} from 'wnd-themes/lib/colorPalette';
import {mL2} from '>shared/styles/mixins/margins';

interface BackLinkProps {
  external?: boolean;
  linkText: string;
  href: string;
}

const LighterLink = styled(Link)`
  color: ${gray500};
  ${center};

  :hover {
    svg {
      fill: ${gray600};
    }

    span {
      color: ${gray600};
    }
  }
`;

export const BackLink: React.FC<BackLinkProps> = ({href, external = false, linkText}) => {
  const linkPathProps = external ? {href: href} : {to: href};

  return (
    <LighterLink {...linkPathProps} variant={LinkVariant.Neutral}>
      <Icon aria-hidden src={arrowBackIcon} />
      <span css={[center, mL2]}>{linkText}</span>
    </LighterLink>
  );
};
