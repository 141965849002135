import * as React from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {vr4, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {authApi} from '>root/apis';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {PasswordChangeForm} from '>components/passwordChange/passwordChangeForm';
import {PROFILE_URL} from '>root/env';
import {rollbarLogger} from '>lib/logger';
import {LoginQueryParams} from '>root/types/enums';
import {HttpError} from 'wnd-util';
import {BackLink} from '>components/backLink';
import {pageChangeEffect} from '>lib/sideEffects';

export const ChangePasswordPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation();
  React.useEffect(pageChangeEffect(t('passwordChange:pageTitle')));

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = React.useCallback(async (data) => {
    try {
      await authApi.changePassword(data.currentPassword, data.newPassword);

      location.replace(`${PROFILE_URL}?message=${LoginQueryParams.PasswordChanged}`);
    } catch (error) {
      const httpError = error as HttpError;

      if (httpError.status === 400) {
        setErrorMessage(t('passwordChange:form.currentPasswordError'));
      } else {
        setErrorMessage(t('common:error.default.message'));
        rollbarLogger.error(error as Error);
      }
    }
  }, []);

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('resetPassword:headingText')}</h1>
          {errorMessage ? (
            <InfoBanner bannerType={BannerType.Error} message={errorMessage} />
          ) : (
            <LightParagraphText>{t('resetPassword:instructions')}</LightParagraphText>
          )}
        </CardHeader>
        <PasswordChangeForm setErrorMessage={setErrorMessage} onSubmit={onSubmit} css={vr6} />
        <BackLink external href={PROFILE_URL} linkText={t('passwordChange:backLinkText')} />
      </FlexCard>
    </CenteredPageContents>
  );
};
