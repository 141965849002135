import {createAction} from '@reduxjs/toolkit';
import {createAppThunk} from 'wnd-react-utils/lib/thunk';

import {IntercomState} from '../reducers/vendors';

export const updateIntercomState = createAction<IntercomState>('@wnd/vendors/updateIntercomState');

export const refreshIntercomPage = createAppThunk(
  '@wnd/vendors/refreshIntercomPage',
  async (_, thunkApi) => {
    const intercomState = thunkApi.getState().vendors.intercomState;

    if (intercomState === IntercomState.BOOTED) {
      // Signal an update with no changes to the visitor/account payload so intercom will update its Recent Page Views
      window.Intercom('update');
    }
  }
);
