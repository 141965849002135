import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder} from '>shared/components/form/formBuilder';

import {buildLoginFormFields} from './formBuilder';

export interface LoginFormFields {
  email: string;
  password: string;
}

interface Props {
  onEmailChange: (newValue: string) => void;
  onPasswordChange: (newValue: string) => void;
}

export const LoginFormFieldComponent: React.FC<Props> = ({onEmailChange, onPasswordChange}) => {
  const {register} = useFormContext<LoginFormFields>();
  const {t} = useTranslation('login');

  const formFields = buildLoginFormFields(register, onEmailChange, onPasswordChange, t);

  return <FormBuilder fields={formFields} showErrorSummary={false} />;
};
