import * as React from 'react';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

import {Button, ButtonSizes} from '>shared/components/button/button';
import {LinkButton, LinkSizes} from '>shared/components/actions/linkButton';

import {
  ChangeEmailVerificationFormFieldComponent,
  ChangeEmailVerificationFormFieldTypes,
} from './changeEmailVerificationFormFields';
import {StyledForm} from '>components/flexCard';
import {LightParagraphText} from '>root/pages/page.styles';
import {vr6} from '>shared/styles/mixins/verticalRhythm';

interface Props {
  onResend: () => void;
  onSubmit: SubmitHandler<ChangeEmailVerificationFormFieldTypes>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const ChangeEmailVerificationForm: React.FC<Props> = ({
  onResend,
  onSubmit,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const {t} = useTranslation('changeEmailVerification');
  const formMethods = useForm<ChangeEmailVerificationFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors} = formMethods;

  React.useEffect(() => {
    if (errors.authCode) {
      setErrorMessage(t('changeEmailVerification:invalidCodeErrorMessage'));
    }
  }, [errors]);

  const clearStatusMessages = React.useCallback(() => {
    setErrorMessage('');
    setSuccessMessage('');
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <ChangeEmailVerificationFormFieldComponent onAuthCodeChange={clearStatusMessages} />
          <div>
            <LightParagraphText>
              <InlineText>
                {t('changeEmailVerification:helpText')}
                <LinkButton onClick={onResend} data-qa-attribute="resend-code" size={LinkSizes.LG}>
                  {t('changeEmailVerification:helpButtonText')}
                </LinkButton>
              </InlineText>
            </LightParagraphText>
            <Button
              data-qa-button="verify-email-change-button"
              fullWidth
              size={ButtonSizes.LG}
              type="submit"
            >
              {t('changeEmailVerification:submitButtonText')}
            </Button>
          </div>
        </StyledForm>
      </FormProvider>
    </>
  );
};
const InlineText = styled.span`
  display: inline-flex;
  gap: 0.8rem;
  justify-content: center;
  width: 100%;
  ${vr6};
`;
