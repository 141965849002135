import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {StyledForm} from '>components/flexCard';

import {
  PasswordCreationFormFields,
  PasswordCreationFormFieldTypes,
} from './passwordCreationFormFields';

interface Props {
  buttonText: string;
  onSubmit: SubmitHandler<PasswordCreationFormFieldTypes>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

export const PasswordCreationForm: React.FC<Props> = ({buttonText, onSubmit, setErrorMessage, className}) => {
  const {t} = useTranslation('passwordCreation');
  const formMethods = useForm<PasswordCreationFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors} = formMethods;

  const clearErrorMessage = React.useCallback(() => setErrorMessage(''), []);

  React.useEffect(() => {
    if (errors.newPassword) {
      setErrorMessage(t('passwordCreation:formatErrorMessage'));
    } else if (errors.newPasswordMatch) {
      setErrorMessage(t('passwordCreation:matchingErrorMessage'));
    }
  }, [errors]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)} className={className}>
        <PasswordCreationFormFields
          onPasswordMatchChange={clearErrorMessage}
          onPasswordChange={clearErrorMessage}
        />
        <Button data-qa-button="sign-in-button" fullWidth size={ButtonSizes.LG} type="submit">
          {buttonText}
        </Button>
      </StyledForm>
    </FormProvider>
  );
};
