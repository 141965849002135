import styled from '@emotion/styled';

import * as colors from 'wnd-themes/lib/colorPalette';
import {dropShadow3Xl} from '>shared/styles/mixins/shadows';
import {SVG} from '>shared/components/icon/svg';
import {vr6} from '>shared/styles/mixins/verticalRhythm';

export const FlexCard = styled.section`
  background-color: ${colors.white};
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2.4rem 0;
  padding: 3.2rem 4rem;
  text-align: left;
  ${dropShadow3Xl};

  min-height: 60rem;
  width: 100%;
  max-width: 44rem;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Logo = styled(SVG)`
  height: 6rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 2.4rem;
`;

export const InputFields = styled.div``;
