import * as React from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {HttpError} from 'wnd-util';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {
  CandidateDetailAccessFormFieldComponent,
  CandidateDetailAccessFormFieldTypes,
} from './candidateDetailAccessFormFields';
import {rollbarLogger} from '>lib/logger';
import {StyledForm} from '>components/flexCard';
import {wonscoreApi} from '>root/apis';
import {WONSCORE_URL} from '>root/env';

interface Props {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface RouteMatch {
  params: {openingId: string; candidateId: string};
}

export const CandidateDetailAccessForm: React.FC<Props> = ({setErrorMessage}) => {
  const {t} = useTranslation('candidateDetail');

  const match = useRouteMatch('/openings/:openingId/candidates/:candidateId');
  const formMethods = useForm<CandidateDetailAccessFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit} = formMethods;

  const onSubmit: SubmitHandler<CandidateDetailAccessFormFieldTypes> = React.useCallback(
    async (data: CandidateDetailAccessFormFieldTypes) => {
      try {
        const routeMatch = (match as unknown) as RouteMatch;
        const {params} = routeMatch;
        const responseData = await wonscoreApi.authenticateCandidateDetailAccess(
          {accessCode: data.accessCode},
          {candidateId: params.candidateId}
        );

        window.location.replace(
          `${WONSCORE_URL}/openings/${params.openingId}/candidates/${params.candidateId}#${responseData.data.value}`
        );
      } catch (error) {
        const httpError = error as HttpError;

        if (httpError.status === 404 || httpError.status === 400) {
          setErrorMessage(t('candidateDetail:errorMessage'));
        } else {
          rollbarLogger.error(error as Error);
          setErrorMessage(t('common:error.default.message'));
        }
      }
    },
    []
  );

  const clearErrorMessage = React.useCallback(() => setErrorMessage(''), []);

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <CandidateDetailAccessFormFieldComponent onAccessCodeChange={clearErrorMessage} />
          <Button
            data-qa-button="submit-candidate-access-code"
            fullWidth
            size={ButtonSizes.LG}
            type="submit"
          >
            {t('candidateDetail:submitButtonText')}
          </Button>
        </StyledForm>
      </FormProvider>
    </>
  );
};
