/*
    DO NOT MODIFY THIS FILE. THIS FILE WAS AUTOMATICALLY GENERATED
*/
import * as generatedTypes from "./wonscore.types";
import { httpClient } from "wnd-util";

function pick(params: any, fields: string[]): Record<string, string> {
  return fields.reduce((acc, key) => {
    if ((params as any)[key] !== undefined) {
      acc[key] = (params as any)[key];
    }
    return acc;
  }, {} as Record<string, any>);
}

export class API {
  private client = httpClient.create();

  constructor(private rootUrl: string) {}

  setAccessToken(accessToken: string) {
    this.client.setAuthToken(accessToken);
  }

  clearAccessToken() {
    this.client.clearAuthToken();
  }

  static getAccountByMasterAccountId_queryParams: string[] = ["fields"];
  async getAccountByMasterAccountId(
    params: { masterAccountId: string } & { fields?: string }
  ): Promise<httpClient.Response<generatedTypes.account & void & void & void>> {
    const queryObj = pick(params, API.getAccountByMasterAccountId_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts/masterAccount/${params.masterAccountId}`,
      queryParams: queryObj,
    });
  }

  static getAccountById_queryParams: string[] = ["fields"];
  async getAccountById(
    params: { accountId: string } & { fields?: string }
  ): Promise<httpClient.Response<generatedTypes.account & void & void>> {
    const queryObj = pick(params, API.getAccountById_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts/${params.accountId}`,
      queryParams: queryObj,
    });
  }

  static getUsageMetrics_queryParams: string[] = [];
  async getUsageMetrics(
    params: { accountId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.accountUsageMetrics & void & void>
  > {
    const queryObj = pick(params, API.getUsageMetrics_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts/${params.accountId}/usageMetrics`,
      queryParams: queryObj,
    });
  }

  static listAccountsForUser_queryParams: string[] = [];
  async listAccountsForUser(): Promise<
    httpClient.Response<generatedTypes.accountList & void>
  > {
    const queryObj = {};

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts`,
      queryParams: queryObj,
    });
  }

  static createAccountFromMasterAccount_queryParams: string[] = [];
  async createAccountFromMasterAccount(
    body: generatedTypes.newAccount
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/accounts`,
      queryParams: queryObj,
      body,
    });
  }

  static handleBillingEvent_queryParams: string[] = [];
  async handleBillingEvent(
    body: generatedTypes.billingEvent
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/accounts/billingEvent`,
      queryParams: queryObj,
      body,
    });
  }

  static searchAccountsForToolkit_queryParams: string[] = ["limit"];
  async searchAccountsForToolkit(
    params: { searchCriteria: string } & { limit?: number }
  ): Promise<
    httpClient.Response<generatedTypes.searchAccountsForToolkitResult & void>
  > {
    const queryObj = pick(params, API.searchAccountsForToolkit_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts/search/${params.searchCriteria}`,
      queryParams: queryObj,
    });
  }

  static updateUserRole_queryParams: string[] = [];
  async updateUserRole(
    body: generatedTypes.accountUserRoleUpdate,
    params: { accountId: string; userId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateUserRole_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/user/${params.userId}/role`,
      queryParams: queryObj,
      body,
    });
  }

  static sendInvitation_queryParams: string[] = [];
  async sendInvitation(
    body: generatedTypes.invitationAdminUserLookup,
    params: { accountId: string; userId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.sendInvitation_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/user/${params.userId}/sendInvitation`,
      queryParams: queryObj,
      body,
    });
  }

  static addAccountUser_queryParams: string[] = [];
  async addAccountUser(
    body: generatedTypes.newAccountUser,
    params: { accountId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.accountUser & void & void & void & void>
  > {
    const queryObj = pick(params, API.addAccountUser_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/accounts/${params.accountId}/users`,
      queryParams: queryObj,
      body,
    });
  }

  static removeAccountUser_queryParams: string[] = [];
  async removeAccountUser(
    params: { accountId: string; userId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.removeAccountUser_queryParams);

    return this.client.request({
      method: "delete",
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.userId}`,
      queryParams: queryObj,
    });
  }

  static updateCanAddJobOpeningsForUser_queryParams: string[] = [];
  async updateCanAddJobOpeningsForUser(
    body: generatedTypes.accountUserCanAddJobOpeningsUpdate,
    params: { accountId: string; userId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(
      params,
      API.updateCanAddJobOpeningsForUser_queryParams
    );

    return this.client.request({
      method: "patch",
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.userId}/canAddJobOpenings`,
      queryParams: queryObj,
      body,
    });
  }

  static updateCanManageBillingForUser_queryParams: string[] = [];
  async updateCanManageBillingForUser(
    body: generatedTypes.accountUserCanManageBillingUpdate,
    params: { accountId: string; userId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(
      params,
      API.updateCanManageBillingForUser_queryParams
    );

    return this.client.request({
      method: "patch",
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.userId}/canManageBilling`,
      queryParams: queryObj,
      body,
    });
  }

  static isUserAnAccountOwner_queryParams: string[] = [];
  async isUserAnAccountOwner(
    params: { userId: string } & {}
  ): Promise<httpClient.Response<boolean & void>> {
    const queryObj = pick(params, API.isUserAnAccountOwner_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/accounts/users/${params.userId}`,
      queryParams: queryObj,
    });
  }

  static updateAccountName_queryParams: string[] = [];
  async updateAccountName(
    body: generatedTypes.nameUpdate,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateAccountName_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/name`,
      queryParams: queryObj,
      body,
    });
  }

  static updateAccountPublicName_queryParams: string[] = [];
  async updateAccountPublicName(
    body: generatedTypes.publicNameUpdate,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateAccountPublicName_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/publicName`,
      queryParams: queryObj,
      body,
    });
  }

  static updateFeatureFlag_queryParams: string[] = [];
  async updateFeatureFlag(
    body: generatedTypes.featureFlagUpdate,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateFeatureFlag_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/updateFeatureFlag`,
      queryParams: queryObj,
      body,
    });
  }

  static updateBillingOverride_queryParams: string[] = [];
  async updateBillingOverride(
    body: generatedTypes.billingOverrideUpdate,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateBillingOverride_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/updateBillingOverride`,
      queryParams: queryObj,
      body,
    });
  }

  static updateAccountApproval_queryParams: string[] = [];
  async updateAccountApproval(
    body: generatedTypes.isApproved,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateAccountApproval_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/accounts/${params.accountId}/approval`,
      queryParams: queryObj,
      body,
    });
  }

  static acceptInvite_queryParams: string[] = [];
  async acceptInvite(
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.acceptInvite_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/accounts/${params.accountId}/users/acceptInvite`,
      queryParams: queryObj,
    });
  }

  static subscribeToAccountEvents_queryParams: string[] = [];
  async subscribeToAccountEvents(
    body: generatedTypes.eventSubscription,
    params: { accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.subscribeToAccountEvents_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/accounts/${params.accountId}/events/subscribe`,
      queryParams: queryObj,
      body,
    });
  }

  static listCandidates_queryParams: string[] = [
    "accountId",
    "openingId",
    "candidateIds",
    "status",
    "sort",
    "skip",
    "limit",
    "fields",
  ];
  async listCandidates(
    params: {} & {
      accountId?: string;
      openingId?: string;
      candidateIds?: string;
      status?: string;
      sort?: string;
      skip?: number;
      limit?: number;
      fields?: string;
    }
  ): Promise<
    httpClient.Response<generatedTypes.candidateList & void & void & void>
  > {
    const queryObj = pick(params, API.listCandidates_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/candidates`,
      queryParams: queryObj,
    });
  }

  static createCandidate_queryParams: string[] = [];
  async createCandidate(
    body: generatedTypes.newCandidate
  ): Promise<
    httpClient.Response<generatedTypes.candidate & void & void & void>
  > {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates`,
      queryParams: queryObj,
      body,
    });
  }

  static searchCandidatesForToolkit_queryParams: string[] = [];
  async searchCandidatesForToolkit(
    params: { searchCriteria: string } & {}
  ): Promise<
    httpClient.Response<
      generatedTypes.searchCandidatesForToolkitList & void & void
    >
  > {
    const queryObj = pick(params, API.searchCandidatesForToolkit_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/candidates/search/${params.searchCriteria}`,
      queryParams: queryObj,
    });
  }

  static getCandidateById_queryParams: string[] = ["fields"];
  async getCandidateById(
    params: { candidateId: string } & { fields?: string }
  ): Promise<httpClient.Response<generatedTypes.candidate & void & void>> {
    const queryObj = pick(params, API.getCandidateById_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/candidates/${params.candidateId}`,
      queryParams: queryObj,
    });
  }

  static getCandidateFeedback_queryParams: string[] = [];
  async getCandidateFeedback(
    params: { candidateId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.candidateFeedback & void & void & void>
  > {
    const queryObj = pick(params, API.getCandidateFeedback_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/candidates/${params.candidateId}/feedback`,
      queryParams: queryObj,
    });
  }

  static authenticateCandidateReport_queryParams: string[] = [];
  async authenticateCandidateReport(
    body: generatedTypes.candidateAccessCode,
    params: { candidateId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.resourceToken & void & void & void>
  > {
    const queryObj = pick(params, API.authenticateCandidateReport_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/access`,
      queryParams: queryObj,
      body,
    });
  }

  static authenticateCandidateDetailAccess_queryParams: string[] = [];
  async authenticateCandidateDetailAccess(
    body: generatedTypes.candidateAccessCode,
    params: { candidateId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.resourceToken & void & void & void>
  > {
    const queryObj = pick(
      params,
      API.authenticateCandidateDetailAccess_queryParams
    );

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/detailAccess`,
      queryParams: queryObj,
      body,
    });
  }

  static getSampleCandidateByFirstName_queryParams: string[] = [
    "accountId",
    "fields",
  ];
  async getSampleCandidateByFirstName(
    params: { firstName: string } & { accountId?: string; fields?: string }
  ): Promise<httpClient.Response<generatedTypes.candidate & void & void>> {
    const queryObj = pick(
      params,
      API.getSampleCandidateByFirstName_queryParams
    );

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/candidates/sample/${params.firstName}`,
      queryParams: queryObj,
    });
  }

  static copyCandidate_queryParams: string[] = [];
  async copyCandidate(
    body: generatedTypes.copyCandidate,
    params: { candidateId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.candidate & void & void & void & void>
  > {
    const queryObj = pick(params, API.copyCandidate_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/copy`,
      queryParams: queryObj,
      body,
    });
  }

  static copyCandidateAdministration_queryParams: string[] = [];
  async copyCandidateAdministration(
    body: generatedTypes.targetCandidate,
    params: { candidateId: string; administrationId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.copyCandidateAdministration_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/administrations/${params.administrationId}/copy`,
      queryParams: queryObj,
      body,
    });
  }

  static followCandidate_queryParams: string[] = [];
  async followCandidate(
    body: generatedTypes.followCandidate,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.followCandidate_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/candidates/${params.candidateId}/follow`,
      queryParams: queryObj,
      body,
    });
  }

  static updateCandidateContactInfo_queryParams: string[] = [];
  async updateCandidateContactInfo(
    body: generatedTypes.updateCandidateContactInfo,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateCandidateContactInfo_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/candidates/${params.candidateId}/contact`,
      queryParams: queryObj,
      body,
    });
  }

  static updateSelectionStatus_queryParams: string[] = [];
  async updateSelectionStatus(
    body: generatedTypes.selectionStatus,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateSelectionStatus_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/candidates/${params.candidateId}/selectionStatus`,
      queryParams: queryObj,
      body,
    });
  }

  static updateResultsHidden_queryParams: string[] = [];
  async updateResultsHidden(
    body: generatedTypes.resultsHidden,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateResultsHidden_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/candidates/${params.candidateId}/resultsHidden`,
      queryParams: queryObj,
      body,
    });
  }

  static subscribeToCandidateEvents_queryParams: string[] = [];
  async subscribeToCandidateEvents(
    body: generatedTypes.eventSubscription,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.subscribeToCandidateEvents_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/events/subscribe`,
      queryParams: queryObj,
      body,
    });
  }

  static generateCandidateResourceToken_queryParams: string[] = [];
  async generateCandidateResourceToken(
    body: generatedTypes.openingInformation,
    params: { candidateId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.resourceToken & void>> {
    const queryObj = pick(
      params,
      API.generateCandidateResourceToken_queryParams
    );

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateId}/resourceToken`,
      queryParams: queryObj,
      body,
    });
  }

  static anonymizeCandidateData_queryParams: string[] = [];
  async anonymizeCandidateData(
    params: { candidateEmail: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.anonymizeCandidateData_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/candidates/${params.candidateEmail}/anonymizeData`,
      queryParams: queryObj,
    });
  }

  static createCustomJob_queryParams: string[] = [];
  async createCustomJob(
    body: generatedTypes.NewCustomJob
  ): Promise<httpClient.Response<generatedTypes.CustomJob & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/customJobs`,
      queryParams: queryObj,
      body,
    });
  }

  static listCustomJobs_queryParams: string[] = ["accountId", "isEnabled"];
  async listCustomJobs(
    params: {} & { accountId?: string; isEnabled?: boolean }
  ): Promise<httpClient.Response<generatedTypes.CustomJobList & void & void>> {
    const queryObj = pick(params, API.listCustomJobs_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/customJobs`,
      queryParams: queryObj,
    });
  }

  static cloneCustomJob_queryParams: string[] = [];
  async cloneCustomJob(
    params: { customJobId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.CustomJob & void & void>> {
    const queryObj = pick(params, API.cloneCustomJob_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/customJobs/${params.customJobId}/clone`,
      queryParams: queryObj,
    });
  }

  static updateCustomJob_queryParams: string[] = [];
  async updateCustomJob(
    body: generatedTypes.CustomJobUpdate,
    params: { customJobId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateCustomJob_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/customJobs/${params.customJobId}`,
      queryParams: queryObj,
      body,
    });
  }

  static getCustomJob_queryParams: string[] = [];
  async getCustomJob(
    params: { customJobId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.CustomJob & void & void>> {
    const queryObj = pick(params, API.getCustomJob_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/customJobs/${params.customJobId}`,
      queryParams: queryObj,
    });
  }

  static getCustomJobReportFileDownloadUrl_queryParams: string[] = [];
  async getCustomJobReportFileDownloadUrl(
    params: { customJobId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.reportFileDownloadUrl & void & void>
  > {
    const queryObj = pick(
      params,
      API.getCustomJobReportFileDownloadUrl_queryParams
    );

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/customJobs/${params.customJobId}/reportFileDownloadUrl`,
      queryParams: queryObj,
    });
  }

  static getCustomJobReportFileUploadUrl_queryParams: string[] = [];
  async getCustomJobReportFileUploadUrl(
    body: generatedTypes.reportContentType,
    params: { customJobId: string } & {}
  ): Promise<
    httpClient.Response<generatedTypes.reportFileUploadUrl & void & void & void>
  > {
    const queryObj = pick(
      params,
      API.getCustomJobReportFileUploadUrl_queryParams
    );

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/customJobs/${params.customJobId}/reportFileUploadUrl`,
      queryParams: queryObj,
      body,
    });
  }

  static listOpenings_queryParams: string[] = [
    "accountId",
    "status",
    "isSample",
    "isIntegrated",
    "minimumRole",
    "viewedByUser",
    "tags",
    "searchText",
    "sort",
    "limit",
    "skip",
    "fields",
  ];
  async listOpenings(
    params: {} & {
      accountId?: string;
      status?: string;
      isSample?: boolean;
      isIntegrated?: boolean;
      minimumRole?: string;
      viewedByUser?: boolean;
      tags?: string;
      searchText?: string;
      sort?: string;
      limit?: number;
      skip?: number;
      fields?: string;
    }
  ): Promise<httpClient.Response<generatedTypes.openingList & void & void>> {
    const queryObj = pick(params, API.listOpenings_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/openings`,
      queryParams: queryObj,
    });
  }

  static createOpening_queryParams: string[] = [];
  async createOpening(
    body: generatedTypes.newOpening
  ): Promise<httpClient.Response<generatedTypes.opening & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/openings`,
      queryParams: queryObj,
      body,
    });
  }

  static listOpeningNames_queryParams: string[] = [
    "accountId",
    "status",
    "isIntegrated",
    "openingIds",
  ];
  async listOpeningNames(
    params: {} & {
      accountId?: string;
      status?: string;
      isIntegrated?: string;
      openingIds?: string;
    }
  ): Promise<
    httpClient.Response<generatedTypes.openingNameList & void & void>
  > {
    const queryObj = pick(params, API.listOpeningNames_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/openings/name`,
      queryParams: queryObj,
    });
  }

  static userHasOpeningsWithMinimumRole_queryParams: string[] = ["accountId"];
  async userHasOpeningsWithMinimumRole(
    params: { userId: string; minimumRole: string } & { accountId?: string }
  ): Promise<httpClient.Response<boolean & void & void>> {
    const queryObj = pick(
      params,
      API.userHasOpeningsWithMinimumRole_queryParams
    );

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/openings/users/${params.userId}/role/${params.minimumRole}`,
      queryParams: queryObj,
    });
  }

  static cloneOpening_queryParams: string[] = [];
  async cloneOpening(
    body: generatedTypes.newOpening,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.opening & void & void & void>> {
    const queryObj = pick(params, API.cloneOpening_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/openings/${params.openingId}/clone`,
      queryParams: queryObj,
      body,
    });
  }

  static applyToOpening_queryParams: string[] = [];
  async applyToOpening(
    body: generatedTypes.openingApplication,
    params: { applyShortCode: string } & {}
  ): Promise<httpClient.Response<string & void & void & void>> {
    const queryObj = pick(params, API.applyToOpening_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/openings/${params.applyShortCode}/apply`,
      queryParams: queryObj,
      body,
    });
  }

  static getOpeningById_queryParams: string[] = ["fields"];
  async getOpeningById(
    params: { openingId: string } & { fields?: string }
  ): Promise<httpClient.Response<generatedTypes.opening & void & void>> {
    const queryObj = pick(params, API.getOpeningById_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/openings/${params.openingId}`,
      queryParams: queryObj,
    });
  }

  static updateOpeningDetails_queryParams: string[] = [];
  async updateOpeningDetails(
    body: generatedTypes.openingDetails,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateOpeningDetails_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteOpening_queryParams: string[] = ["accountId"];
  async deleteOpening(
    params: { openingId: string } & { accountId?: string }
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.deleteOpening_queryParams);

    return this.client.request({
      method: "delete",
      url: `${this.rootUrl}/openings/${params.openingId}`,
      queryParams: queryObj,
    });
  }

  static updateFeatures_queryParams: string[] = [];
  async updateFeatures(
    body: generatedTypes.featuresUpdate,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateFeatures_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}/updateFeatures`,
      queryParams: queryObj,
      body,
    });
  }

  static bulkUpdateRoles_queryParams: string[] = [];
  async bulkUpdateRoles(
    body: generatedTypes.openingUsersAndRolesUpdate
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/openings/roles`,
      queryParams: queryObj,
      body,
    });
  }

  static followOpening_queryParams: string[] = ["userId"];
  async followOpening(
    body: generatedTypes.followOpening,
    params: { openingId: string } & { userId?: string }
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.followOpening_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}/follow`,
      queryParams: queryObj,
      body,
    });
  }

  static updateIsArchived_queryParams: string[] = [];
  async updateIsArchived(
    body: generatedTypes.isArchived,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateIsArchived_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}/isArchived`,
      queryParams: queryObj,
      body,
    });
  }

  static updateIsIntegrated_queryParams: string[] = [];
  async updateIsIntegrated(
    body: generatedTypes.isIntegratedUpdate,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateIsIntegrated_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}/isIntegrated`,
      queryParams: queryObj,
      body,
    });
  }

  static updateApplyLink_queryParams: string[] = [];
  async updateApplyLink(
    body: generatedTypes.applyLinkUpdate,
    params: { openingId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.updateApplyLink_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/openings/${params.openingId}/applyLink`,
      queryParams: queryObj,
      body,
    });
  }

  static getProductById_queryParams: string[] = [];
  async getProductById(
    params: { productId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.product & void & void>> {
    const queryObj = pick(params, API.getProductById_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/products/${params.productId}`,
      queryParams: queryObj,
    });
  }

  static publishUserEvent_queryParams: string[] = [];
  async publishUserEvent(
    body: generatedTypes.userEvent,
    params: { userId: string; type: string } & {}
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, API.publishUserEvent_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/userEvents/${params.userId}/${params.type}`,
      queryParams: queryObj,
      body,
    });
  }

  static getUserPreferencesByUserId_queryParams: string[] = [];
  async getUserPreferencesByUserId(
    params: { userId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.userPreferences & void>> {
    const queryObj = pick(params, API.getUserPreferencesByUserId_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/userPreferences/${params.userId}`,
      queryParams: queryObj,
    });
  }

  static updateUserPreferencesByUserId_queryParams: string[] = [];
  async updateUserPreferencesByUserId(
    body: generatedTypes.userPreferencesUpdate,
    params: { userId: string } & {}
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(
      params,
      API.updateUserPreferencesByUserId_queryParams
    );

    return this.client.request({
      method: "patch",
      url: `${this.rootUrl}/userPreferences/${params.userId}`,
      queryParams: queryObj,
      body,
    });
  }

  static listWidgetsForUser_queryParams: string[] = [];
  async listWidgetsForUser(
    params: { userId: string; accountId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.widgetList & void>> {
    const queryObj = pick(params, API.listWidgetsForUser_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/widgets/${params.userId}/${params.accountId}`,
      queryParams: queryObj,
    });
  }

  static updateQuickStartWidget_queryParams: string[] = [];
  async updateQuickStartWidget(
    body: generatedTypes.quickStartWidgetUpdate,
    params: { userId: string; accountId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.updateQuickStartWidget_queryParams);

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/widgets/${params.userId}/${params.accountId}/quickStart`,
      queryParams: queryObj,
      body,
    });
  }

  static dismissWidget_queryParams: string[] = [];
  async dismissWidget(
    params: { userId: string; accountId: string; type: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.dismissWidget_queryParams);

    return this.client.request({
      method: "delete",
      url: `${this.rootUrl}/widgets/${params.userId}/${params.accountId}/${params.type}`,
      queryParams: queryObj,
    });
  }

  static listWorkflows_queryParams: string[] = [
    "candidateId",
    "fields",
    "userIds",
  ];
  async listWorkflows(
    params: {} & { candidateId?: string; fields?: string; userIds?: string }
  ): Promise<httpClient.Response<generatedTypes.workflowList & void & void>> {
    const queryObj = pick(params, API.listWorkflows_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/workflows`,
      queryParams: queryObj,
    });
  }

  static createWorkflow_queryParams: string[] = [];
  async createWorkflow(
    body: generatedTypes.newWorkflow
  ): Promise<httpClient.Response<generatedTypes.workflow & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/workflows`,
      queryParams: queryObj,
      body,
    });
  }

  static getWorkflowById_queryParams: string[] = [];
  async getWorkflowById(
    params: { workflowId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.workflow & void & void>> {
    const queryObj = pick(params, API.getWorkflowById_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/workflows/${params.workflowId}`,
      queryParams: queryObj,
    });
  }

  static sendWorkflowInvitation_queryParams: string[] = [];
  async sendWorkflowInvitation(
    params: { workflowId: string } & {}
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, API.sendWorkflowInvitation_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/workflows/${params.workflowId}/send/invitation`,
      queryParams: queryObj,
    });
  }

  static subscribeToWorkflowEvents_queryParams: string[] = [];
  async subscribeToWorkflowEvents(
    body: generatedTypes.eventSubscription,
    params: { workflowId: string } & {}
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, API.subscribeToWorkflowEvents_queryParams);

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/workflows/${params.workflowId}/events/subscribe`,
      queryParams: queryObj,
      body,
    });
  }

  static createPreviewWorkflow_queryParams: string[] = [];
  async createPreviewWorkflow(
    body: generatedTypes.newPreviewWorkflow
  ): Promise<
    httpClient.Response<generatedTypes.workflow & void & void & void>
  > {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/previewWorkflows`,
      queryParams: queryObj,
      body,
    });
  }

  static search_queryParams: string[] = ["searchCriteria", "skip", "limit"];
  async search(
    params: { accountId: string } & {
      searchCriteria?: string;
      skip?: number;
      limit?: number;
    }
  ): Promise<
    httpClient.Response<generatedTypes.searchResultsList & void & void>
  > {
    const queryObj = pick(params, API.search_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/search/${params.accountId}`,
      queryParams: queryObj,
    });
  }

  static getDataExtracts_queryParams: string[] = ["accountId"];
  async getDataExtracts(
    params: {} & { accountId?: string }
  ): Promise<httpClient.Response<generatedTypes.dataExtracts & void>> {
    const queryObj = pick(params, API.getDataExtracts_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/dataExtracts`,
      queryParams: queryObj,
    });
  }

  static generateDataExtract_queryParams: string[] = [];
  async generateDataExtract(
    body: generatedTypes.newDataExtract
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/dataExtracts`,
      queryParams: queryObj,
      body,
    });
  }

  static getDataExtractDownloadUrl_queryParams: string[] = [];
  async getDataExtractDownloadUrl(
    params: { dataExtractId: string } & {}
  ): Promise<httpClient.Response<generatedTypes.dataExtractUrl & void & void>> {
    const queryObj = pick(params, API.getDataExtractDownloadUrl_queryParams);

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/dataExtracts/${params.dataExtractId}/url/`,
      queryParams: queryObj,
    });
  }

  static getConfig_queryParams: string[] = [];
  async getConfig(): Promise<
    httpClient.Response<generatedTypes.config & void & void>
  > {
    const queryObj = {};

    return this.client.request({
      method: "get",
      url: `${this.rootUrl}/config`,
      queryParams: queryObj,
    });
  }

  static updateInWorkflowSurvey_queryParams: string[] = [];
  async updateInWorkflowSurvey(
    body: generatedTypes.configSurvey
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "put",
      url: `${this.rootUrl}/config/workflow/inWorkflowSurvey`,
      queryParams: queryObj,
      body,
    });
  }

  static createDemoCandidates_queryParams: string[] = [];
  async createDemoCandidates(
    body: generatedTypes.createDemoCandidates
  ): Promise<httpClient.Response<generatedTypes.candidate & void & void>> {
    const queryObj = {};

    return this.client.request({
      method: "post",
      url: `${this.rootUrl}/internal/candidates/demo`,
      queryParams: queryObj,
      body,
    });
  }
}
