import * as React from 'react';
import {useSelector} from 'react-redux';

import {LoadingScreen} from '>shared/components/loadingScreen';
import {assertAuthStateContainsValidToken} from '>lib/assert';
import {clearAndLogout, clearAndUnimpersonate} from '>root/store/actions/auth';
import {useAppDispatch} from '>root/store/main';

interface Props {
  location: Location;
}

export const LogoutPage: React.FC<Props> = ({location}) => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state) => state.auth);

  React.useEffect(() => {
    assertAuthStateContainsValidToken(authState);
    if (authState.isImpersonated) {
      dispatch(clearAndUnimpersonate());
    } else {
      const redirectUri = new URLSearchParams(location.search).get('redirect_uri');
      console.log(redirectUri);
      dispatch(clearAndLogout(redirectUri ?? undefined));
    }
  }, []);

  return <LoadingScreen />;
};
