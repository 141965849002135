import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';
import {vr2} from '>shared/styles/mixins/verticalRhythm';

import {passwordIs8CharsWithNumAndCapitalLetter} from '>root/utils/passwordValidation';

export interface PasswordCreationFormFieldTypes {
  newPassword: string;
  newPasswordMatch: string;
}

interface Props {
  onPasswordChange: (newValue: string) => void;
  onPasswordMatchChange: (newValue: string) => void;
}

export const PasswordCreationFormFields: React.FC<Props> = ({
  onPasswordMatchChange,
  onPasswordChange,
}) => {
  const {register, getValues} = useFormContext<PasswordCreationFormFieldTypes>();
  const {t} = useTranslation('passwordCreation');

  return (
    <FormBuilder
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: 'newPassword',
          label: t('passwordCreation:form.passwordInputLabel'),
          component: (
            <Input
              autoComplete="new-password"
              css={vr2}
              helperText={t('passwordCreation:form.passwordHelperText')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onPasswordChange(event.target.value)
              }
              placeholder={t('passwordCreation:form.passwordPlaceholder')}
              type="password"
              ref={register({
                required: true,
                validate: (newValue) => {
                  return passwordIs8CharsWithNumAndCapitalLetter(newValue);
                },
              })}
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'newPasswordMatch',
          label: t('passwordCreation:form.passwordMatchlInputLabel'),
          component: (
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onPasswordMatchChange(event.target.value)
              }
              placeholder={t('passwordCreation:form.passwordMatchPlaceholder')}
              ref={register({
                required: true,
                validate: () => getValues('newPassword') === getValues('newPasswordMatch'),
              })}
              type="password"
            />
          ),
        },
      ]}
      showErrorSummary={false}
    />
  );
};
