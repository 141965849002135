import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {detect} from 'detect-browser';
import {ThemeProvider} from '@emotion/react';
import {Provider} from 'react-redux';
import {WndError} from 'wnd-react-utils/lib/errors';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import {assertExists} from 'wnd-util/lib/assert';

import {GlobalStyles} from '>styles/globalStyles';
import {dvpEmployeeTheme} from 'wnd-themes/lib/dvpEmployeeTheme';

import './i18n';
import App from './App';
import {store} from './store/main';
import {routes} from './routes/routes';
import {redirectToErrorPage} from '>lib/redirect';
import * as serviceWorker from './serviceWorker';
import {initializeToken} from './store/actions/auth';
import {MissingTokenError} from './errors';

window.addEventListener('unhandledrejection', (event) => {
  redirectToErrorPage(event.reason);
});

async function initializeTokenState() {
  const initializeTokenResponse = await store.dispatch(initializeToken());

  if (initializeToken.rejected.match(initializeTokenResponse)) {
    const errorPayload = initializeTokenResponse.payload as WndError;
    if (errorPayload.code === MissingTokenError.code) {
      throw errorPayload;
    }
  }
}

function applyBrowserClass(element: HTMLElement) {
  const browserInfo = detect();

  element.className = browserInfo?.name ?? 'unknown-browser';
}

async function mountApp() {
  try {
    await initializeTokenState();
  } catch (err) {
    if ((err as any).name === 'MissingTokenError') {
      // Do not render the application and wait for redirection
      // initiated by the thrower.
      return;
    }
    throw err;
  }

  const rootElement = document.getElementById('root');
  assertExists(rootElement, 'must have element with id "root"');
  applyBrowserClass(rootElement);

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={dvpEmployeeTheme}>
        <GlobalStyles />
        <App routes={routes} />
      </ThemeProvider>
    </Provider>,

    rootElement
  );
}

// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // @ts-ignore
  module.hot.accept('./App', mountApp);
}

mountApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
