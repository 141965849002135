import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {RouteChildrenProps} from 'react-router-dom';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {vr4, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {CandidateDetailAccessForm} from '>components/candidateDetailAccess/candidateDetailAccessForm';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {pageChangeEffect} from '>lib/sideEffects';
import {SELECTION_PRODUCT_NAME} from '>root/env';

export const CandidateDetailAccessPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('candidateDetail');
  React.useEffect(pageChangeEffect(t('candidateDetail:pageTitle'), SELECTION_PRODUCT_NAME));

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('candidateDetail:headingText')}</h1>
          {errorMessage && (
            <InfoBanner bannerType={BannerType.Error} css={vr4} message={errorMessage} />
          )}
          <LightParagraphText>{t('candidateDetail:instructions')}</LightParagraphText>
        </CardHeader>
        <CandidateDetailAccessForm setErrorMessage={setErrorMessage} />
      </FlexCard>
    </CenteredPageContents>
  );
};
