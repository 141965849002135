import {createAsyncThunk, AsyncThunkPayloadCreator} from '@reduxjs/toolkit';
import {HttpError} from 'wnd-util';
import {WndError} from 'wnd-react-utils/lib/errors';

import {RootState} from './main';

export type ThunkApiConfig = {
  state: RootState;
  rejectValue: WndError;
};

// This creates an action creator that is aware of our App's state
//  using the default `createAsyncThunk` would return `unknown` if attempting to
//  fetch the state through `thunkApi.getState()`
export function createAppThunk<Returned, ThunkArg = void>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>
) {
  // @ts-ignore
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(type, async (arg, thunkApi) => {
    try {
      return await payloadCreator(arg, thunkApi);
    } catch (err) {
      if (err instanceof HttpError) {
        return thunkApi.rejectWithValue({
          name: err.name,
          message: err.message,
          isUserFacing: err.isUserFacing,
          stack: err.stack,
        });
      }

      const wndError = err as WndError;

      return thunkApi.rejectWithValue({
        name: wndError.name,
        message: wndError.message,
        isUserFacing: false,
        code: wndError.code,
      });
    }
  });
}
