import {EnhancedAPI as AuthApi} from './lib/enhancedAuth';
import {API as WonscoreApi} from './generated/wonscore';

import {API_AUTH_URL, AUTH_URL, API_WONSCORE_URL} from './env';

import {setUpAPIs} from '>auth/apis';

export const authApi = new AuthApi(API_AUTH_URL, AUTH_URL);
export const wonscoreApi = new WonscoreApi(API_WONSCORE_URL);

const APIS = [authApi, wonscoreApi];

export const {setAPIAccessToken, getAPIAccessToken, clearAPIAccessTokens} = setUpAPIs(APIS);
