import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder} from '>shared/components/form/formBuilder';

import {buildForgotPasswordFormFields} from './formBuilder';

export interface ForgotPasswordFormFieldTypes {
  email: string;
}

export const ForgotPasswordFormFieldComponent: React.FC = () => {
  const {register} = useFormContext<ForgotPasswordFormFieldTypes>();
  const {t} = useTranslation('forgotPassword');

  const formFields = buildForgotPasswordFormFields(register, t);

  return <FormBuilder fields={formFields} showErrorSummary={false} />;
};
