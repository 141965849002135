import styled from '@emotion/styled';

import {textMdRegular, textSmRegular} from '>shared/components/typography/designSystemTypography';
import {centerItems, verticallyCenterItems} from '>shared/styles/mixins/alignment';
import {gray100, gray500} from 'wnd-themes/lib/colorPalette';
import {ImpersonationBanner, ImpersonationBannerProps} from '>shared/components/banners/impersonationBanner';

export const AppBody = styled.main`
  background-color: ${gray100};
  flex: 1 0 auto;
`;

export const CenteredPageContents = styled.div`
  ${verticallyCenterItems}
  ${centerItems}
  ${textSmRegular}
  flex-direction: column;
  text-align: center;
  gap: 3.6rem;
`;

export const LightParagraphText = styled.p`
  ${textMdRegular};
  color: ${gray500};
`;

export const StyledBanner = styled(ImpersonationBanner)`
  left: 50%;
  transform: translate(-50%);
`;
