import commonenTranslations from './en/common.json';
import loginenTranslations from './en/login.json';
import passwordCreationenTranslations from './en/passwordCreation.json';
import forgotPasswordenTranslations from './en/forgotPassword.json';
import resetPasswordenTranslations from './en/resetPassword.json';
import userCreationenTranslations from './en/userCreation.json';
import errorenTranslations from './en/error.json';
import changeEmailenTranslations from './en/changeEmail.json';
import changeEmailVerificationenTranslations from './en/changeEmailVerification.json';
import passwordChangeenTranslations from './en/passwordChange.json';
import candidateDetailenTranslations from './en/candidateDetail.json';

export const nameSpaces = ["common","login","passwordCreation","forgotPassword","resetPassword","userCreation","error","changeEmail","changeEmailVerification","passwordChange","candidateDetail"];

export const defaultNS = 'common'

export const resources = {
  en: {
    common: commonenTranslations,
    login: loginenTranslations,
    passwordCreation: passwordCreationenTranslations,
    forgotPassword: forgotPasswordenTranslations,
    resetPassword: resetPasswordenTranslations,
    userCreation: userCreationenTranslations,
    error: errorenTranslations,
    changeEmail: changeEmailenTranslations,
    changeEmailVerification: changeEmailVerificationenTranslations,
    passwordChange: passwordChangeenTranslations,
    candidateDetail: candidateDetailenTranslations,
  },
};

export const languages: Record<string, any> = {
  en: {nativeName: 'US'},
};

export const fallbackLanguage = 'en';
