import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {TFunction} from 'i18next';

import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';

import {isValidEmailAddress} from '>shared/lib/emailValidation';

export function buildChangeEmailFormFields(
  register: UseFormMethods['register'],
  onEmailChange: (newValue: string) => void,
  onPasswordChange: (newValue: string) => void,
  t: TFunction
): FormBuilderFields {
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onEmailChange(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPasswordChange(event.target.value);
  };
  const emailRequiredString = t('changeEmail:form.emailRequiredError');
  const passwordRequiredString = t('changeEmail:form.passwordRequiredError');

  function validEmailString(emailToValidate: string): string | undefined {
    const isValid = isValidEmailAddress(emailToValidate);

    return isValid ? undefined : emailRequiredString;
  }

  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'email',
      label: t('changeEmail:form.emailInputLabel'),
      component: (
        <Input
          autoComplete="email"
          onChange={handleEmailChange}
          placeholder={t('changeEmail:form.emailPlaceholder')}
          type="text"
          ref={register({
            required: emailRequiredString,
            validate: validEmailString,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'password',
      label: t('changeEmail:form.passwordInputLabel'),
      component: (
        <Input
          autoComplete="current-password"
          onChange={handlePasswordChange}
          placeholder={t('changeEmail:form.passwordPlaceholder')}
          ref={register({
            required: passwordRequiredString,
          })}
          type="password"
        />
      ),
    },
  ];
}
