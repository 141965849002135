import * as React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, RouteChildrenProps} from 'react-router-dom';

export function withAuthRequired(WrappedComponent: React.ComponentType<RouteChildrenProps>) {
  const ComponentRequiringAuthentication = (props: RouteChildrenProps) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return isAuthenticated ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect to={`/login?redirect_uri=${encodeURIComponent(window.location.href)}`} />
    );
  };

  return ComponentRequiringAuthentication;
}
