export enum LoginQueryParams {
  EmailChangeExpired = 'email-change-expired',
  PasswordChanged = 'password-changed',
  VerificationFailed = 'verification-failed',
  InvalidHash = 'invalid-hash',
}

export enum ProfilePageQueryParams {
  EmailChanged = 'email-changed',
}
