import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';

export interface CandidateDetailAccessFormFieldTypes {
  accessCode: string;
}

interface Props {
  onAccessCodeChange: React.Dispatch<React.SetStateAction<string>>;
}

export const CandidateDetailAccessFormFieldComponent: React.FC<Props> = ({onAccessCodeChange}) => {
  const {register} = useFormContext<CandidateDetailAccessFormFieldTypes>();
  const {t} = useTranslation('candidateDetail');

  const codeRequiredMessage = t('candidateDetail:form.accessCodeError');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAccessCodeChange(event.target.value);
  };

  return (
    <FormBuilder
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: 'accessCode',
          label: t('candidateDetail:form.accessCodeInputLabel'),
          component: (
            <Input
              onChange={handleInputChange}
              placeholder={t('candidateDetail:form.accessCodePlaceholder')}
              type="text"
              ref={register({
                required: codeRequiredMessage,
              })}
            />
          ),
        },
      ]}
      showErrorSummary={false}
    />
  );
};
