import * as React from 'react';
import {FormProvider, UseFormMethods} from 'react-hook-form';

import {LoginFormFieldComponent, LoginFormFields} from './loginFormFields';

interface Props {
  formMethods: UseFormMethods<LoginFormFields>;
  onEmailChange: (newValue: string) => void;
  onPasswordChange: (newValue: string) => void;
}

export const LoginForm: React.FC<Props> = ({formMethods, onEmailChange, onPasswordChange}) => {
  return (
    <>
      <FormProvider {...formMethods}>
        <LoginFormFieldComponent
          onEmailChange={onEmailChange}
          onPasswordChange={onPasswordChange}
        />
      </FormProvider>
    </>
  );
};
