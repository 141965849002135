import {CombinedState, combineReducers} from 'redux';
import {authReducer, AuthState} from './authReducer';

import {vendorsReducer, VendorsState} from './vendors';

export type AuthCombinedState = CombinedState<{
  auth: AuthState;
  vendors: VendorsState;
}>;

export const rootReducer = combineReducers({
  auth: authReducer,
  vendors: vendorsReducer,
});
