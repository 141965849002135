import {authApi, setAPIAccessToken, getAPIAccessToken, clearAPIAccessTokens} from '>root/apis';
import {AUTH_URL, CLIENT_ID, CLIENT_SCOPES} from '>root/env';
import {toolkitRoles as ToolkitRoles} from '>generated/auth.types';

import {createAppThunk} from '../thunk';
import {assert} from 'wnd-util';
import {MissingTokenError} from '>root/errors';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';

export enum TokenType {
  User = 'user',
  Anonymous = 'anonymous',
}

interface NavItem {
  name: string;
  url: string;
}

export interface UserData {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  verified: boolean;
  toolkitRoles?: ToolkitRoles;
  wonscoreUser: boolean;
  navItems?: NavItem[];
}

export interface UserToken {
  id: string;
  token_type: TokenType;
  app?: string;
  expires_in: number;
  scope: string[];
  impersonatedByUserId?: string;
  user?: UserData;
  resource?: {
    id: string;
  };
}

function encodeKeyValue(key: string, value: boolean | string | undefined) {
  assert.assertExists(value, 'We filtered out undefined values before this function');

  if (value === true) {
    return key;
  }

  return `${key}=${encodeURIComponent(value)}`;
}

function createAndSubmitFormPost(baseUrl: string, route: string, redirect?: string) {
  const form = document.createElement('form');
  const formAction = redirect
    ? `${baseUrl}/${route}?redirect_uri=${redirect}`
    : `${baseUrl}/${route}`;

  form.action = formAction;
  form.method = 'POST';

  document.body.appendChild(form);
  form.submit();
}

export const clearTokens = createAppThunk('@wnd/clear', clearAPIAccessTokens);

export const clearAndLogout = createAppThunk(
  'clearAndLogout',
  async (redirectUri: string | undefined, thunkApi) => {
    await thunkApi.dispatch(clearTokens());

    const redirect = `/login${redirectUri ? '?redirect_uri=' + redirectUri : ''}`;
    createAndSubmitFormPost(AUTH_URL, 'logout', redirect);
  }
);

export const clearAndUnimpersonate = createAppThunk(
  'clearAndUnimpersonate',
  async (_, thunkApi) => {
    await thunkApi.dispatch(clearTokens());

    createAndSubmitFormPost(AUTH_URL, 'unimpersonate');
  }
);

const AUTH_TOKEN_LENGTH = 124;

function isAuthToken(tokenString: string) {
  // Auth Tokens always have a specific length.
  //  We don't have a better indicator of whether its an auth token or not.
  return tokenString.length === AUTH_TOKEN_LENGTH;
}

function initiateOAuth(allowAnonymous: boolean) {
  const params: Record<string, boolean | string | undefined> = {
    allow_anonymous: allowAnonymous,
    client_id: CLIENT_ID,
    scope: CLIENT_SCOPES,
    redirect_uri: window.location.href.replace('#!/', ''),
  };

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== false && params[key] !== undefined)
    .map((key) => encodeKeyValue(key, params[key]));

  // TODO: Investigate not using implicit flow: https://wonderlic.atlassian.net/browse/SW-6750
  window.location.href = `${AUTH_URL}/oauth2/implicit?${queryString.join('&')}`;
  // Throw to stop execution of all other code before redirection occurs.
  // This should be caught at the highest scope so it does not become an unhandled rejection.
  throw new MissingTokenError();
}

export const getAccessToken = createAppThunk('@wnd/auth/getAccessToken', (_) => {
  let accessToken: string | null = null;
  const hashToken = window.location.hash.slice(1);
  if (isAuthToken(hashToken)) {
    accessToken = hashToken;
    window.location.hash = '#';
  } else {
    accessToken = getAPIAccessToken();
    
    // In the event you are not authenticated and are trying to access an Auth page that requires it, we want to call the oAuth implicit route with a false value
    const authRequiredPaths = [findPathFromLabel(RouteLabel.ChangeEmail), findPathFromLabel(RouteLabel.ChangePassword)];
    if (authRequiredPaths.includes(window.location.pathname)) {
      initiateOAuth(false);
      throw new Error('Unreachable');
    }
  }

  if (!accessToken) {
    initiateOAuth(true); // this is unique to webui-auth because all of the pages are available to logged out users
    throw new Error('Unreachable');
  }

  setAPIAccessToken(accessToken);

  return accessToken;
});

export const getUserToken = createAppThunk('@wnd/auth/getUserToken', async () => {
  try {
    const response = await authApi.me();

    return response.data;
  } catch (err) {
    clearAPIAccessTokens();
    throw err;
  }
});

export const initializeToken = createAppThunk('@wnd/auth/initializeToken', async (_, thunkApi) => {
  let accessTokenAction = await thunkApi.dispatch(getAccessToken());
  if (getAccessToken.rejected.match(accessTokenAction)) {
    throw accessTokenAction.payload;
  }

  let userTokenAction = await thunkApi.dispatch(getUserToken());
  if (getUserToken.rejected.match(userTokenAction)) {
    // Attempt to re-authenticate in case token is expired
    await thunkApi.dispatch(clearTokens());
    accessTokenAction = await thunkApi.dispatch(getAccessToken());
    if (getAccessToken.rejected.match(accessTokenAction)) {
      throw accessTokenAction.payload;
    }
    userTokenAction = await thunkApi.dispatch(getUserToken());
    if (getUserToken.rejected.match(userTokenAction)) {
      throw userTokenAction.payload;
    }
  }
});
