import * as React from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {ChangeEmailFormFieldComponent, ChangeEmailFormFieldTypes} from './changeEmailFormFields';
import {StyledForm} from '>components/flexCard';

interface Props {
  buttonText: string;
  onSubmit: SubmitHandler<ChangeEmailFormFieldTypes>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

export const ChangeEmailForm: React.FC<Props> = ({buttonText, onSubmit, setErrorMessage, className}) => {
  const {t} = useTranslation('changeEmail');

  const formMethods = useForm<ChangeEmailFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors} = formMethods;

  React.useEffect(() => {
    if (errors.password) {
      setErrorMessage(t('changeEmail:form.passwordErrorMessage'));
    }
  }, [errors]);

  const clearErrorMessage = React.useCallback(() => setErrorMessage(''), []);

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} css={className}>
          <ChangeEmailFormFieldComponent
            onEmailChange={clearErrorMessage}
            onPasswordChange={clearErrorMessage}
          />
          <Button
            data-qa-button="change-email-button"
            fullWidth
            size={ButtonSizes.LG}
            type="submit"
          >
            {buttonText}
          </Button>
        </StyledForm>
      </FormProvider>
    </>
  );
};
