import {createReducer} from '@reduxjs/toolkit';
import {updateIntercomState} from '../actions/vendors';

export enum IntercomState {
  NOT_READY,
  READY_TO_BOOT,
  BOOTED,
}

const DEFAULT_STATE: Readonly<VendorsState> = {
  intercomState: IntercomState.NOT_READY,
};

export interface VendorsState {
  intercomState: IntercomState;
}

export const vendorsReducer = createReducer<VendorsState>(DEFAULT_STATE, (builder) => {
  builder.addCase(updateIntercomState, (state, action) => {
    return {
      ...state,
      intercomState: action.payload,
    };
  });
});
