import {tokenUser, toolkitRoles as ToolkitRoles} from '>generated/auth.types';
import {
  BILLING_URL,
  WONSCORE_TOOLKIT_URL,
  RESEARCH_TOOLKIT_URL,
  ACQUIRETM_TOOLKIT_URL,
  APPLICANTPRO_TOOLKIT_URL,
  BIZMERLIN_TOOLKIT_URL,
  HIRINGTHING_TOOLKIT_URL,
  ICIMS_TOOLKIT_URL,
  JOBMA_TOOLKIT_URL,
  NEWTON_TOOLKIT_URL,
} from '>root/env';

export function getLoginRedirectRoute(user: tokenUser, redirectUriParam: null | string): string {
  if (user.toolkitRoles) {
    const roles: ToolkitRoles = user.toolkitRoles;

    if (roles?.wonscore) {
      return WONSCORE_TOOLKIT_URL;
    } else if (roles?.research) {
      return RESEARCH_TOOLKIT_URL;
    } else if (roles?.acquiretm) {
      return ACQUIRETM_TOOLKIT_URL;
    } else if (roles?.applicantpro) {
      return APPLICANTPRO_TOOLKIT_URL;
    } else if (roles?.bizmerlin) {
      return BIZMERLIN_TOOLKIT_URL;
    } else if (roles?.hiringthing) {
      return HIRINGTHING_TOOLKIT_URL;
    } else if (roles?.jobma) {
      return JOBMA_TOOLKIT_URL;
    } else if (roles?.icims) {
      return ICIMS_TOOLKIT_URL;
    } else if (roles?.newton) {
      return NEWTON_TOOLKIT_URL;
    }
  } else if (redirectUriParam) {
    return redirectUriParam;
  }
  return `${BILLING_URL}/productPicker`;
}
