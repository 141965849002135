import {WndError} from 'wnd-react-utils/lib/errors';
import {history} from '>root/history';

export function redirectToErrorPage(error?: WndError): void {
  const errorPath = '/error';
  if (error?.code) {
    history.replace({pathname: errorPath, search: `?code=${error.code}`});
  } else {
    history.replace(errorPath);
  }
}
