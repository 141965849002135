import * as React from 'react';
import * as ReactGA from 'react-ga';
import {Router, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {AppContainer} from '>shared/components/layouts/responsive/responsiveAppComponents';
import {AppRoute} from '>shared/lib/navigation';
import {browserChecking, BrowserFeatures} from '>shared/components/util/diagnosticUtils';
import {Diagnostic} from '>shared/components/util/diagnostic';
import {ModalProvider} from '>shared/components/modal/useModal';

import {AppBody} from './pages/page.styles';
import {Footer} from './components/footer';
import {history} from './history';
import {Impersonation} from '>components/impersonation';
import {IntercomState} from '>store/reducers/vendors';
import {rollbarLogger} from '>lib/logger';
import {RouterErrorBoundary} from '>components/routerErrorBoundary';
import {store} from '>store/main';
import {updateIntercomState} from '>store/actions/vendors';

function App({routes}: {routes: AppRoute[]}) {
  const requiredFeatures = [
    BrowserFeatures.COOKIES,
    BrowserFeatures.LOCALSTORAGE,
    BrowserFeatures.SESSIONSTORAGE,
  ];
  const checkResult = browserChecking(requiredFeatures);

  if (process.env.ANALYTICS_ENABLED && process.env.ANALYTICS_ACCOUNT_ID) {
    const trackingId = process.env.ANALYTICS_ACCOUNT_ID;
    ReactGA.initialize(trackingId);

    history.listen((location) => {
      ReactGA.set({page: location.pathname});
      ReactGA.pageview(location.pathname);
    });
  }

  React.useEffect(() => {
    if (process.env.INTERCOM_ENABLED) {
      store.dispatch(updateIntercomState(IntercomState.READY_TO_BOOT));
    }
  }, []);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <AppContainer className="App">
      <ModalProvider>
        <React.Suspense fallback="">
          <Router history={history}>
            {isAuthenticated && <Impersonation />}
            <RouterErrorBoundary rollbarLogger={rollbarLogger}>
              {checkResult.length ? (
                <Diagnostic missingFeature={checkResult} />
              ) : (
                <Switch>
                  <Route
                    path="/"
                    render={(props) => (
                      <AppBody>
                        <Switch>
                          {routes.map((route) => (
                            <Route
                              key={route.path}
                              exact
                              path={`${route.path}`}
                              render={(props) => <route.Component {...props} />}
                            ></Route>
                          ))}
                        </Switch>
                      </AppBody>
                    )}
                  />
                </Switch>
              )}
            </RouterErrorBoundary>
          </Router>
          <Footer />
        </React.Suspense>
      </ModalProvider>
    </AppContainer>
  );
}

export default App;
