import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

import {rollbarLogger} from '>lib/logger';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {StyledForm} from '>components/flexCard';
import {
  ForgotPasswordFormFieldComponent,
  ForgotPasswordFormFieldTypes,
} from './forgotPasswordFormFields';
import {authApi} from '>root/apis';

interface Props {
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  className?: string
}

export const ForgotPasswordForm: React.FC<Props> = ({setSuccessMessage, className}) => {
  const {t} = useTranslation('forgotPassword');

  const formMethods = useForm<ForgotPasswordFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit} = formMethods;

  const onSubmit: SubmitHandler<ForgotPasswordFormFieldTypes> = React.useCallback(
    async (data: ForgotPasswordFormFieldTypes) => {
      try {
        await authApi.sendForgotPasswordEmail({emailAddress: data.email});
      } catch (error) {
        rollbarLogger.error(error as Error);
      }
      setSuccessMessage(t('forgotPassword:emailSentSuccess'));
    },
    []
  );

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} className={className}>
          <ForgotPasswordFormFieldComponent />
          <Button
            data-qa-button="send-password-reset-button"
            fullWidth
            size={ButtonSizes.LG}
            type="submit"
          >
            {t('forgotPassword:submitButtonText')}
          </Button>
        </StyledForm>
      </FormProvider>
    </>
  );
};
