import * as React from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {vr4, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {BackLink} from '>components/backLink';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {ForgotPasswordForm} from '>components/forgotPassword/forgotPasswordForm';
import {pageChangeEffect} from '>lib/sideEffects';

export const ForgotPasswordPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('forgotPassword');
  React.useEffect(pageChangeEffect(t('forgotPassword:pageTitle')));

  const [successMessage, setSuccessMessage] = React.useState<string>('');

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('forgotPassword:headingText')}</h1>
          {successMessage ? (
            <InfoBanner bannerType={BannerType.Info} message={successMessage} />
          ) : (
            <LightParagraphText>{t('forgotPassword:instructions')}</LightParagraphText>
          )}
        </CardHeader>
        {!successMessage && <ForgotPasswordForm setSuccessMessage={setSuccessMessage} css={vr6} />}
        <BackLink
          href={findPathFromLabel(RouteLabel.Login)}
          linkText={t('forgotPassword:backLinkText')}
        />
      </FlexCard>
    </CenteredPageContents>
  );
};
