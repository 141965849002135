import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder} from '>shared/components/form/formBuilder';

import {buildChangeEmailFormFields} from './formBuilder';

export interface ChangeEmailFormFieldTypes {
  email: string;
  password: string;
}

interface Props {
  onEmailChange: (newValue: string) => void;
  onPasswordChange: (newValue: string) => void;
}

export const ChangeEmailFormFieldComponent: React.FC<Props> = ({
  onEmailChange,
  onPasswordChange,
}) => {
  const {register} = useFormContext<ChangeEmailFormFieldTypes>();
  const {t} = useTranslation('changeEmail');

  const formFields = buildChangeEmailFormFields(register, onEmailChange, onPasswordChange, t);

  return <FormBuilder fields={formFields} showErrorSummary={false} />;
};
