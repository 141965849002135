import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';
import {vr8} from '>shared/styles/mixins/verticalRhythm';

import {passwordIs8CharsWithNumAndCapitalLetter} from '>root/utils/passwordValidation';

export interface PasswordChangeFormFieldTypes {
  currentPassword: string;
  newPassword: string;
  newPasswordMatch: string;
}

interface Props {
  onValueChange: (newValue: string) => void;
}

export const PasswordChangeFormFields: React.FC<Props> = ({onValueChange}) => {
  const {register, getValues} = useFormContext<PasswordChangeFormFieldTypes>();

  const {t} = useTranslation();
  const passwordRequiredString = t('login:form.passwordRequiredError');

  return (
    <FormBuilder
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: 'currentPassword',
          label: t('passwordChange:form.currentPasswordInputLabel'),
          component: (
            <Input
              autoComplete="current-password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onValueChange(event.target.value)
              }
              placeholder={t('passwordChange:form.currentPasswordPlaceholder')}
              ref={register({
                required: passwordRequiredString,
              })}
              type="password"
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'newPassword',
          label: t('passwordCreation:form.passwordInputLabel'),
          component: (
            <Input
              autoComplete="new-password"
              helperText={t('passwordCreation:form.passwordHelperText')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onValueChange(event.target.value)
              }
              placeholder={t('passwordCreation:form.passwordPlaceholder')}
              type="password"
              ref={register({
                required: true,
                validate: (newValue) => {
                  return passwordIs8CharsWithNumAndCapitalLetter(newValue);
                },
              })}
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'newPasswordMatch',
          label: t('passwordCreation:form.passwordMatchlInputLabel'),
          component: (
            <Input
              css={vr8}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onValueChange(event.target.value)
              }
              placeholder={t('passwordCreation:form.passwordMatchPlaceholder')}
              ref={register({
                required: true,
                validate: () => getValues('newPassword') === getValues('newPasswordMatch'),
              })}
              type="password"
            />
          ),
        },
      ]}
      showErrorSummary={false}
    />
  );
};
