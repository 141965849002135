import * as React from 'react';
import {HttpError} from 'wnd-util';
import {useSelector} from 'react-redux';
import {RouteChildrenProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {vr4, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {assertAuthenticatedAuthState} from '>lib/assert';
import {authApi} from '>root/apis';
import {BackLink} from '>components/backLink';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {CenteredPageContents, LightParagraphText} from './page.styles';
import {ChangeEmailForm} from '>components/changeEmail/changeEmailForm';
import {ChangeEmailFormFieldTypes} from '>components/changeEmail/changeEmailFormFields';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {PROFILE_URL} from '>root/env';
import {rollbarLogger} from '>lib/logger';
import {pageChangeEffect} from '>lib/sideEffects';

export const ChangeEmailPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('changeEmail');
  React.useEffect(pageChangeEffect(t('changeEmail:pageTitle')));

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = React.useCallback(async (data: ChangeEmailFormFieldTypes) => {
    try {
      await authApi.changeUserEmail(
        {newEmail: data.email, password: data.password},
        {userId: user.id}
      );
      location.replace(
        `${findPathFromLabel(RouteLabel.VerifyEmail)}?newEmail=${encodeURIComponent(data.email)}`
      );
    } catch (err) {
      const httpError = err as HttpError;

      if (httpError.status === 409) {
        setErrorMessage(t('changeEmail:form.emailErrorMessage'));
      } else if (httpError.status === 400) {
        setErrorMessage(t('changeEmail:form.passwordErrorMessage'));
      } else {
        setErrorMessage(t('changeEmail:form.genericErrorMessage'));
        rollbarLogger.error(err as Error);
      }
    }
  }, []);

  const authState = useSelector((state) => state.auth);

  assertAuthenticatedAuthState(authState);
  const user = authState.token.user;

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('changeEmail:headingText')}</h1>
          {errorMessage ? (
            <InfoBanner bannerType={BannerType.Error} message={errorMessage} />
          ) : (
            <LightParagraphText>{t('changeEmail:instructions')}</LightParagraphText>
          )}
        </CardHeader>
        <ChangeEmailForm
          css={vr6}
          buttonText={t('changeEmail:primaryButtonText')}
          setErrorMessage={setErrorMessage}
          onSubmit={onSubmit}
        />
        <BackLink external href={PROFILE_URL} linkText={t('changeEmail:backLinkText')} />
      </FlexCard>
    </CenteredPageContents>
  );
};
