import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {TFunction} from 'i18next';

import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';

export function buildLoginFormFields(
  register: UseFormMethods['register'],
  onEmailChange: (newValue: string) => void,
  onPasswordChange: (newValue: string) => void,
  t: TFunction
): FormBuilderFields {
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onEmailChange(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPasswordChange(event.target.value);
  };
  const emailRequiredString = t('login:form.emailRequiredError');
  const passwordRequiredString = t('login:form.passwordRequiredError');

  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'email',
      label: t('login:form.emailInputLabel'),
      component: (
        <Input
          autoComplete="email"
          onChange={handleEmailChange}
          placeholder={t('login:form.emailPlaceholder')}
          type="text"
          ref={register({
            required: emailRequiredString,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'password',
      label: t('login:form.passwordInputLabel'),
      component: (
        <Input
          autoComplete="current-password"
          onChange={handlePasswordChange}
          placeholder={t('login:form.passwordPlaceholder')}
          ref={register({
            required: passwordRequiredString,
          })}
          type="password"
        />
      ),
    },
  ];
}
