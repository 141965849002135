import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {CenteredPageContents, LightParagraphText} from './page.styles';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {pageChangeEffect} from '>lib/sideEffects';

import {centerText} from '>shared/styles/mixins/alignment';
import {
  displayXsSemibold,
  textXlRegular,
} from '>shared/components/typography/designSystemTypography';
import {vr4, vr8} from '>shared/styles/mixins/verticalRhythm';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {BackLink} from '>components/backLink';

export const AuthErrorPage: React.FC<{}> = () => {
  const {t} = useTranslation('error');

  React.useEffect(pageChangeEffect(t('error:pageTitle')));

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>{t('error:heading')}</h1>
        </CardHeader>
        <p css={[centerText, textXlRegular]}>{t('error:message')}</p>
        <LightParagraphText css={centerText}>{t('error:subMessage')}</LightParagraphText>
        <BackLink href={'/login'} linkText={t('error:backLinkText')} />
      </FlexCard>
    </CenteredPageContents>
  );
};
