import {assert} from 'wnd-util';

import {AuthErrorPage} from '>root/pages/authErrorPage';
import {ChangeEmailPage} from '>root/pages/changeEmailPage';
import {ChangeEmailVerificationPage} from '>root/pages/changeEmailVerificationPage';
import {ChangePasswordPage} from '>root/pages/changePasswordPage';
import {CreatePasswordPage} from '>root/pages/createPasswordPage';
import {CreateUserPage} from '>root/pages/createUserPage';
import {ForgotPasswordPage} from '>root/pages/forgotPasswordPage';
import {LoginPage} from '>root/pages/loginPage';
import {LogoutPage} from '>root/pages/logoutPage';
import {ResetPasswordPage} from '>root/pages/resetPasswordPage';
import {VerifyUserPage} from '>root/pages/verifyUserPage';

import {AppRoute, findRoutePathFromLabel} from '>shared/lib/navigation';
import {withAuthRequired} from '>components/wrappers/authWrapper';
import {CandidateDetailAccessPage} from '>root/pages/candidateDetailAccessPage';

export enum RouteLabel {
  CandidateDetailAccess = 'Candidate Detail',
  ChangeEmail = 'Change Email',
  ChangePassword = 'Change Password',
  CreatePassword = 'Create Password',
  CreateUser = 'Create User',
  ForgotPassword = 'Forgot Password',
  Home = 'Home Page',
  Login = 'Login Page',
  Logout = 'Logout',
  ResetPassword = 'Reset Password',
  Error = 'Error Page',
  VerifyEmail = 'Verify Email',
  VerifyUser = 'Verify User',
}
export const routes: Array<AppRoute<RouteLabel>> = [
  {
    path: '/',
    label: RouteLabel.Login,
    Component: LoginPage,
  },
  {
    path: '/email/change',
    label: RouteLabel.ChangeEmail,
    Component: withAuthRequired(ChangeEmailPage),
  },
  {
    path: '/email/change/verify',
    label: RouteLabel.VerifyEmail,
    Component: withAuthRequired(ChangeEmailVerificationPage),
  },
  {
    path: '/error',
    label: RouteLabel.Error,
    Component: AuthErrorPage,
  },
  {
    path: '/login',
    label: RouteLabel.Login,
    Component: LoginPage,
  },
  {
    path: '/logout',
    label: RouteLabel.Logout,
    Component: LogoutPage,
  },
  {
    path: '/openings/:openingId/candidates/:candidateId/access',
    label: RouteLabel.CandidateDetailAccess,
    Component: CandidateDetailAccessPage,
  },
  {
    path: '/password/change',
    label: RouteLabel.ChangePassword,
    Component: withAuthRequired(ChangePasswordPage),
  },
  {
    path: '/password/create',
    label: RouteLabel.CreatePassword,
    Component: withAuthRequired(CreatePasswordPage),
  },
  {
    path: '/password/forgot',
    label: RouteLabel.ForgotPassword,
    Component: ForgotPasswordPage,
  },
  {
    path: '/password/reset',
    label: RouteLabel.ResetPassword,
    Component: withAuthRequired(ResetPasswordPage),
  },
  {
    path: '/user/create',
    label: RouteLabel.CreateUser,
    Component: CreateUserPage,
  },
  {
    path: '/user/verify',
    label: RouteLabel.VerifyUser,
    Component: VerifyUserPage,
  },
];

export function findPathFromLabel(label: RouteLabel) {
  const path = findRoutePathFromLabel(label, routes);
  assert.assertExists(path, 'All path labels should be referenced by the routes');
  return path;
}
