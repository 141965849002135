import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder} from '>shared/components/form/formBuilder';
import {FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';

export interface ChangeEmailVerificationFormFieldTypes {
  authCode: string;
}

interface Props {
  onAuthCodeChange: (newValue: string) => void;
}

export const ChangeEmailVerificationFormFieldComponent: React.FC<Props> = ({onAuthCodeChange}) => {
  const {register} = useFormContext<ChangeEmailVerificationFormFieldTypes>();
  const {t} = useTranslation('changeEmailVerification');

  const handleAuthChodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAuthCodeChange(event.target.value);
  };

  return (
    <FormBuilder
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: 'authCode',
          label: t('changeEmailVerification:form.authCodeInputLabel'),
          component: (
            <Input
              helperText={t('changeEmailVerification:form.authCodeHelperText')}
              onChange={handleAuthChodeChange}
              placeholder={t('changeEmailVerification:form.authCodePlaceholder')}
              type="text"
              ref={register({
                required: true,
              })}
            />
          ),
        },
      ]}
      showErrorSummary={false}
    />
  );
};
