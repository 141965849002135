import * as React from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, ButtonSizes} from '>shared/components/button/button';

import {PasswordChangeFormFields, PasswordChangeFormFieldTypes} from './passwordChangeFormFields';
import {StyledForm} from '>components/flexCard';

interface Props {
  onSubmit: SubmitHandler<PasswordChangeFormFieldTypes>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

export const PasswordChangeForm: React.FC<Props> = ({onSubmit, setErrorMessage, className}) => {
  const {t} = useTranslation('passwordChange');
  const formMethods = useForm<PasswordChangeFormFieldTypes>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const {handleSubmit, errors} = formMethods;

  const clearErrorMessage = React.useCallback(() => setErrorMessage(''), []);

  React.useEffect(() => {
    if (errors.currentPassword) {
      setErrorMessage(t('passwordChange:form.currentPasswordError'));
    } else if (errors.newPassword) {
      setErrorMessage(t('passwordCreation:formatErrorMessage'));
    } else if (errors.newPasswordMatch) {
      setErrorMessage(t('passwordCreation:matchingErrorMessage'));
    }
  }, [errors]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)} className={className}>
        <PasswordChangeFormFields onValueChange={clearErrorMessage} />
        <Button
          data-qa-button="change-password-button"
          fullWidth
          size={ButtonSizes.LG}
          type="submit"
        >
          {t('passwordChange:submitButtonText')}
        </Button>
      </StyledForm>
    </FormProvider>
  );
};
