import * as React from 'react';
import {useSelector} from 'react-redux';

import {assertAuthenticatedAuthState} from '>lib/assert';
import {history} from '../history';
import {StyledBanner} from '>root/pages/page.styles';

export const Impersonation: React.FC = () => {
  const authInfoStore = useSelector((state) => state.auth);
  assertAuthenticatedAuthState(authInfoStore);

  const isImpersonated = authInfoStore.isImpersonated;

  const unImpersonate = React.useCallback(() => {
    history.push('/logout');
  }, []);

  return (
    <>
      {isImpersonated && (
        <StyledBanner name={authInfoStore.token.user.name} unImpersonate={unImpersonate} />
      )}
    </>
  );
};
