/*
    DO NOT MODIFY THIS FILE. THIS FILE WAS AUTOMATICALLY GENERATED BY WND-SWAGGER-TS-CLIENT-GENERATOR
*/

/* eslint-disable */
import * as generatedTypes from './auth.types';

import {httpClient, RequestOptions, Response, toBearer} from 'wnd-util';
// @ts-ignore
import {buildJsonPatch} from 'wnd-util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pick(params: Record<string, unknown>, fields: string[]): Record<string, string> {
  return fields.reduce((acc, key) => {
    if (params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);
}

interface Options {
  makeSecureRequest?<T>(requestPromise: (accessToken: string) => Promise<T>): Promise<T>;
}

export class Api {
  private client = httpClient.create();

  constructor(private rootUrl: string, private options: Options = {}) {
    this.options = options;
  }

  setAccessToken(accessToken: string): void {
    if (this.options.makeSecureRequest) {
      throw new Error(
        'Should not call setAccessToken method when makeSecureRequest method is provided'
      );
    }

    this.client.setAuthToken(accessToken);
  }

  clearAccessToken(): void {
    if (this.options.makeSecureRequest) {
      throw new Error(
        'Should not call clearAccessToken method when makeSecureRequest method is provided'
      );
    }

    this.client.clearAuthToken();
  }

  private async request<RequestPayload, ResponsePayload, QueryParams>(
    requestOptions: RequestOptions<RequestPayload, QueryParams>
  ): Promise<Response<ResponsePayload>> {
    const updatedRequestOptions = {
      ...requestOptions,
      headers: requestOptions.headers ?? {},
    };

    if (this.options.makeSecureRequest) {
      return this.options.makeSecureRequest((authToken) => {
        updatedRequestOptions.headers.Authorization = toBearer(authToken);

        return this.client.request(updatedRequestOptions);
      });
    } else {
      return this.client.request(updatedRequestOptions);
    }
  }

  static createSecurityCode_queryParams: string[] = [];
  async createSecurityCode(): Promise<httpClient.Response<string>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/security-codes`,
      queryParams: queryObj,
    });
  }

  static getSecurityCode_queryParams: string[] = [];
  async getSecurityCode(
    params: {securityCode: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.securityCode & void & void>> {
    const queryObj = pick(params, Api.getSecurityCode_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/security-codes/${params.securityCode}`,
      queryParams: queryObj,
    });
  }

  static listUsers_queryParams: string[] = ['verified', 'userIds', 'email', 'fields', 'limit'];
  async listUsers(
    params: Record<string, unknown> & {
      verified?: string;
      userIds?: string;
      email?: string;
      fields?: string;
      limit?: number;
    }
  ): Promise<httpClient.Response<generatedTypes.userList & void>> {
    const queryObj = pick(params, Api.listUsers_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users`,
      queryParams: queryObj,
    });
  }

  static createUser_queryParams: string[] = [];
  async createUser(
    body: generatedTypes.newUser
  ): Promise<httpClient.Response<generatedTypes.user & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users`,
      queryParams: queryObj,
      body,
    });
  }

  static getOrCreateUser_queryParams: string[] = [];
  async getOrCreateUser(
    body: generatedTypes.userLookup
  ): Promise<httpClient.Response<generatedTypes.user & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/email/`,
      queryParams: queryObj,
      body,
    });
  }

  static addToolkitUser_queryParams: string[] = [];
  async addToolkitUser(
    body: generatedTypes.newToolkitUser
  ): Promise<httpClient.Response<string & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/toolkit/`,
      queryParams: queryObj,
      body,
    });
  }

  static removeToolkitUser_queryParams: string[] = [];
  async removeToolkitUser(
    params: {emailAddress: string; tkType: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void & void & void>> {
    const queryObj = pick(params, Api.removeToolkitUser_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/toolkit/delete/${params.emailAddress}/${params.tkType}`,
      queryParams: queryObj,
    });
  }

  static getUserByEmail_queryParams: string[] = [];
  async getUserByEmail(
    params: {emailAddress: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.user & void & void>> {
    const queryObj = pick(params, Api.getUserByEmail_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users/email/${params.emailAddress}`,
      queryParams: queryObj,
    });
  }

  static sendForgotPasswordEmail_queryParams: string[] = [];
  async sendForgotPasswordEmail(
    body: generatedTypes.email
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/forgot-password`,
      queryParams: queryObj,
      body,
    });
  }

  static getUserByInvitationCode_queryParams: string[] = ['invitationCode'];
  async getUserByInvitationCode(
    params: Record<string, unknown> & {invitationCode?: string}
  ): Promise<httpClient.Response<generatedTypes.user & void & void>> {
    const queryObj = pick(params, Api.getUserByInvitationCode_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users/invitation`,
      queryParams: queryObj,
    });
  }

  static getUserById_queryParams: string[] = ['fields'];
  async getUserById(
    params: {userId: string} & {fields?: string}
  ): Promise<httpClient.Response<generatedTypes.user & void & void>> {
    const queryObj = pick(params, Api.getUserById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users/${params.userId}`,
      queryParams: queryObj,
    });
  }

  static updateUserById_queryParams: string[] = [];
  async updateUserById(
    body: generatedTypes.userUpdate,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.user & void & void & void>> {
    const queryObj = pick(params, Api.updateUserById_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/users/${params.userId}`,
      queryParams: queryObj,
      body,
    });
  }

  static updateMyUser_queryParams: string[] = [];
  async updateMyUser(
    body: generatedTypes.userUpdate
  ): Promise<httpClient.Response<generatedTypes.user & void & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/users/update/me`,
      queryParams: queryObj,
      body,
    });
  }

  static createOneTimeLoginUrl_queryParams: string[] = [];
  async createOneTimeLoginUrl(
    body: generatedTypes.redirect,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void & void>> {
    const queryObj = pick(params, Api.createOneTimeLoginUrl_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/hash`,
      queryParams: queryObj,
      body,
    });
  }

  static verifyUser_queryParams: string[] = [];
  async verifyUser(
    params: {userId: string; verifyHash: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void & void>> {
    const queryObj = pick(params, Api.verifyUser_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/verify/${params.verifyHash}`,
      queryParams: queryObj,
    });
  }

  static sendInvitationEmail_queryParams: string[] = [];
  async sendInvitationEmail(
    body: generatedTypes.invitationAdminUserLookup,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, Api.sendInvitationEmail_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/sendInvitationEmail`,
      queryParams: queryObj,
      body,
    });
  }

  static sendVerificationEmail_queryParams: string[] = [];
  async sendVerificationEmail(
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, Api.sendVerificationEmail_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/sendVerificationEmail`,
      queryParams: queryObj,
    });
  }

  static changeUserEmail_queryParams: string[] = [];
  async changeUserEmail(
    body: generatedTypes.emailUpdate,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void & void>> {
    const queryObj = pick(params, Api.changeUserEmail_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/change-email`,
      queryParams: queryObj,
      body,
    });
  }

  static verifyUserEmailChange_queryParams: string[] = [];
  async verifyUserEmailChange(
    body: generatedTypes.emailVerification,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, Api.verifyUserEmailChange_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/change-email/verify`,
      queryParams: queryObj,
      body,
    });
  }

  static sendNewAuthCodeForPendingEmailChange_queryParams: string[] = [];
  async sendNewAuthCodeForPendingEmailChange(
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void>> {
    const queryObj = pick(params, Api.sendNewAuthCodeForPendingEmailChange_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/change-email/resend-code`,
      queryParams: queryObj,
    });
  }

  static acceptCookiesNotice_queryParams: string[] = [];
  async acceptCookiesNotice(
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<boolean & void & void>> {
    const queryObj = pick(params, Api.acceptCookiesNotice_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/acceptCookies`,
      queryParams: queryObj,
    });
  }

  static subscribeToUserEvents_queryParams: string[] = [];
  async subscribeToUserEvents(
    body: generatedTypes.eventSubscription,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, Api.subscribeToUserEvents_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/events/subscribe`,
      queryParams: queryObj,
      body,
    });
  }

  static unsubscribeFromUserEvents_queryParams: string[] = [];
  async unsubscribeFromUserEvents(
    body: generatedTypes.eventUnsubscription,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void & void & void>> {
    const queryObj = pick(params, Api.unsubscribeFromUserEvents_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/users/${params.userId}/events/unsubscribe`,
      queryParams: queryObj,
      body,
    });
  }

  static isUserVerified_queryParams: string[] = [];
  async isUserVerified(
    params: {invitationCode: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.inviteVerificationResult & void & void>> {
    const queryObj = pick(params, Api.isUserVerified_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users/invitation/${params.invitationCode}/verified`,
      queryParams: queryObj,
    });
  }

  static getProductInvitationUrl_queryParams: string[] = ['productRedirectUrl'];
  async getProductInvitationUrl(
    params: {userId: string} & {productRedirectUrl?: string}
  ): Promise<httpClient.Response<string & void>> {
    const queryObj = pick(params, Api.getProductInvitationUrl_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/users/${params.userId}/product-invitation-url`,
      queryParams: queryObj,
    });
  }

  static updateUserSettingsById_queryParams: string[] = [];
  async updateUserSettingsById(
    body: generatedTypes.userSettingsUpdate,
    params: {userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.user & void & void & void>> {
    const queryObj = pick(params, Api.updateUserSettingsById_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/usersSettings/${params.userId}`,
      queryParams: queryObj,
      body,
    });
  }

  static me_queryParams: string[] = [];
  async me(): Promise<httpClient.Response<generatedTypes.token & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/tokens/me`,
      queryParams: queryObj,
    });
  }

  static createResourceToken_queryParams: string[] = [];
  async createResourceToken(
    body: generatedTypes.newResourceToken
  ): Promise<httpClient.Response<generatedTypes.resourceTokenValue & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/tokens/resource/create`,
      queryParams: queryObj,
      body,
    });
  }
}
