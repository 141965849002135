import * as React from 'react';
import styled from '@emotion/styled';
import {WEBUI_PRIVACY_URL} from '../env'

import {gray100, gray500} from 'wnd-themes/lib/colorPalette';
import {textSmRegular} from '>shared/components/typography/designSystemTypography';
import { useTranslation } from 'react-i18next';

const LightFooter = styled.footer`
  align-items: baseline;
  background-color: ${gray100};
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  padding-bottom: 0.8rem;

  ${textSmRegular};
  color: ${gray500};

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Footer: React.FC = () => {
  const { t } = useTranslation('common');

  return <LightFooter>
    <a href="https://wonderlic.com">www.wonderlic.com</a>
    <span> © {new Date().getFullYear()} Wonderlic</span>
    <a target="_blank" href={WEBUI_PRIVACY_URL}>{t('common:privacy.title')}</a>
  </LightFooter>
};
