import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {FormBuilder, FormBuilderFieldType, FormBuilderFields} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';
import {vr2, vr4} from '>shared/styles/mixins/verticalRhythm';

import {passwordIs8CharsWithNumAndCapitalLetter} from '>root/utils/passwordValidation';
import {ExistingUserData} from './userCreationForm';

export interface UserCreationFormFieldTypes {
  firstName: string;
  lastName: string;
  jobTitle: string;
  newPassword: string;
  newPasswordMatch: string;
}

interface Props {
  onInputChange: (newValue: string) => void;
  userData?: ExistingUserData
}

export const UserCreationFormFields: React.FC<Props> = ({
  onInputChange,
  userData
}) => {
  const {register, getValues} = useFormContext<UserCreationFormFieldTypes>();
  const {t} = useTranslation('userCreation');

  const {firstName, lastName, jobTitle} = userData ?? {};

  let fields: FormBuilderFields = [];
  if (!firstName) {
    fields.push({
      type: FormBuilderFieldType.Input,
      name: 'firstName',
      label: t('userCreation:form.firstName'),
      component: (
        <Input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(event.target.value)
          }
          autoComplete="first-name"
          css={vr4}
          placeholder={t('userCreation:form.firstNamePlaceholder')}
          ref={register({
            required: true,
            validate: (value) => {
              return value.length > 0;
            },
          })}
        />
      )
    });
  }

  if (!lastName) {
    fields.push({
      type: FormBuilderFieldType.Input,
      name: 'lastName',
      label: t('userCreation:form.lastName'),
      component: (
        <Input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(event.target.value)
          }
          autoComplete="last-name"
          css={vr4}
          placeholder={t('userCreation:form.lastNamePlaceholder')}
          ref={register({
            required: true,
            validate: (value) => {
              return value.length > 0;
            },
          })}
        />
      )
    });
  }

  if (!jobTitle) {
    fields.push({
      type: FormBuilderFieldType.Input,
      name: 'jobTitle',
      label: t('userCreation:form.jobTitle'),
      component: (
        <Input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(event.target.value)
          }
          autoComplete="organization-title"
          css={vr4}
          placeholder={t('userCreation:form.jobTitlePlaceholder')}
          ref={register({
            required: true,
            validate: (value) => {
              return value.length >= 2 && value.length <= 60;
            },
          })}
        />
      )
    });
  }

  return (
    <FormBuilder
      fields={[
        ...fields, 
        {
          type: FormBuilderFieldType.Input,
          name: 'newPassword',
          label: t('userCreation:form.passwordInputLabel'),
          component: (
            <Input
              autoComplete="new-password"
              css={vr2}
              helperText={t('userCreation:form.passwordHelperText')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(event.target.value)
              }
              placeholder={t('userCreation:form.passwordPlaceholder')}
              type="password"
              ref={register({
                required: true,
                validate: (newValue) => {
                  return passwordIs8CharsWithNumAndCapitalLetter(newValue);
                },
              })}
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'newPasswordMatch',
          label: t('userCreation:form.passwordMatchlInputLabel'),
          component: (
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(event.target.value)
              }
              placeholder={t('userCreation:form.passwordMatchPlaceholder')}
              ref={register({
                required: true,
                validate: () => getValues('newPassword') === getValues('newPasswordMatch'),
              })}
              type="password"
            />
          ),
        },
      ]}
      showErrorSummary={false}
    />
  );
};
