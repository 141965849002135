import {INTERCOM_APP_ID, INTERCOM_ENABLED} from '>root/env';
import {store} from '../main';
import {IntercomState} from '../reducers/vendors';
import {updateIntercomState} from '../actions/vendors';

import {AuthCombinedState} from '../reducers/main';

/* Note to devs: Do not use this file as a template for other webuis since we are not using Pendo or capturing any data about logged in users. Look to webui-billing or webui-dvp instead. */

function bootIntercom(): void {
  const intercomPayload = buildIntercomVisitorPayload();
  window.Intercom('boot', intercomPayload);
}

function buildIntercomVisitorPayload() {
  return {
    app_id: INTERCOM_APP_ID,
    domain: window.location.hostname,
  };
}

enum PayloadDeliveryState {
  WAITING_TO_REQUEST_DATA,
  REQUESTING_DATA,
  READY_TO_SEND,
  SEND_COMPLETE,
}

function areAllVendorsReadyToBoot(state: AuthCombinedState): boolean {
  const {intercomState} = state.vendors;
  const isDoneWaitingForIntercomScriptTag =
    !INTERCOM_ENABLED || intercomState === IntercomState.READY_TO_BOOT;

  return isDoneWaitingForIntercomScriptTag;
}

export function registerExternalPayloadSideEffects(rootStore: typeof store) {
  if (!INTERCOM_ENABLED) {
    return;
  }

  // Dispatching in rootStore.subscribe()'s callback either does not update the redux state in real-time
  // or is running the callback multiple times in parallel. Maintain state through payloadDeliveryState
  // instead of trusting the Pendo and Intercom states. They should be reliable in the rest of the app.
  let payloadDeliveryState = PayloadDeliveryState.READY_TO_SEND;

  rootStore.subscribe(() => {
    const state: AuthCombinedState = rootStore.getState();

    if (!areAllVendorsReadyToBoot(state)) {
      return;
    }

    if (INTERCOM_ENABLED) {
      bootIntercom();
      rootStore.dispatch(updateIntercomState(IntercomState.BOOTED));
    }

    payloadDeliveryState = PayloadDeliveryState.SEND_COMPLETE;
  });
}
