import * as React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {assertAuthenticatedAuthState} from '>lib/assert';
import {authApi} from '>root/apis';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {getLoginRedirectRoute} from '>root/utils/loginRedirection';
import {LoginQueryParams} from '>root/types/enums';
import {pageChangeEffect} from '>lib/sideEffects';
import {rollbarLogger} from '>lib/logger';
import {tokenUser} from '>generated/auth.types';

export const VerifyUserPage: React.FC<{}> = () => {
  const {t} = useTranslation();
  React.useEffect(pageChangeEffect(t('userCreation:pageTitle')));

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUriParam = queryParams.get('redirect_uri');
  const verifyHash = queryParams.get('hash');
  const authState = useSelector((state) => state.auth);

  const redirectValidUser = React.useCallback((user: tokenUser) => {
    window.location.replace(getLoginRedirectRoute(user, redirectUriParam));
  }, []);

  React.useEffect(() => {
    if (verifyHash) {
      const onLoadValidation = async () => {
        try {
          await authApi.verifyUser({userId: 'me', verifyHash});

          assertAuthenticatedAuthState(authState);

          const user = authState.token.user;
          redirectValidUser(user);
        } catch (err) {
          rollbarLogger.error(err as Error);
          window.location.replace(
            `${findPathFromLabel(RouteLabel.Login)}?message=${LoginQueryParams.VerificationFailed}`
          );
        }
      };

      onLoadValidation();
    } else {
      if (authState.isValidUserToken && authState.token.user) {
        redirectValidUser(authState.token.user);
      } else {
        window.location.replace(findPathFromLabel(RouteLabel.Login));
      }
    }
  }, [verifyHash]);

  return null;
};
