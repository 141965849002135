import {assert} from 'wnd-util';

// TODO: Autogenerate this file based on data from webpack.overrides.js
//  OR just declare that they exist in a declaration file...

assert.assertExists(
  process.env.ACQUIRETM_TOOLKIT_URL,
  'ACQUIRETM_TOOLKIT_URL must be configured for this to work'
);
export const ACQUIRETM_TOOLKIT_URL = process.env.ACQUIRETM_TOOLKIT_URL;

assert.assertExists(process.env.API_AUTH_URL, 'API_AUTH_URL must be configured for this to work');
export const API_AUTH_URL = process.env.API_AUTH_URL;

assert.assertExists(process.env.AUTH_URL, 'AUTH_URL must be configured for this to work');
export const AUTH_URL = process.env.AUTH_URL;

assert.assertExists(
  process.env.API_WONSCORE_URL,
  'API_WONSCORE_URL must be configured for this to work'
);
export const API_WONSCORE_URL = process.env.API_WONSCORE_URL;

assert.assertExists(
  process.env.APPLICANTPRO_TOOLKIT_URL,
  'APPLICANTPRO_TOOLKIT_URL must be configured for this to work'
);
export const APPLICANTPRO_TOOLKIT_URL = process.env.APPLICANTPRO_TOOLKIT_URL;

assert.assertExists(process.env.BILLING_URL, 'BILLING_URL must be configured for this to work');
export const BILLING_URL = process.env.BILLING_URL;

assert.assertExists(
  process.env.BIZMERLIN_TOOLKIT_URL,
  'BIZMERLIN_TOOLKIT_URL must be configured for this to work'
);
export const BIZMERLIN_TOOLKIT_URL = process.env.BIZMERLIN_TOOLKIT_URL;

assert.assertExists(process.env.CLIENT_ID, 'CLIENT_ID must be configured for this to work');
export const CLIENT_ID = process.env.CLIENT_ID;

assert.assertExists(process.env.CLIENT_SCOPES, 'CLIENT_SCOPES must be configured for this to work');
export const CLIENT_SCOPES = process.env.CLIENT_SCOPES;

assert.assertExists(
  process.env.HIRINGTHING_TOOLKIT_URL,
  'HIRINGTHING_TOOLKIT_URL must be configured for this to work'
);
export const HIRINGTHING_TOOLKIT_URL = process.env.HIRINGTHING_TOOLKIT_URL;

assert.assertExists(
  process.env.ICIMS_TOOLKIT_URL,
  'ICIMS_TOOLKIT_URL must be configured for this to work'
);
export const ICIMS_TOOLKIT_URL = process.env.ICIMS_TOOLKIT_URL;

assert.assertExists(process.env.INTERCOM_APP_ID, 'INTERCOM_APP_ID must be configured');
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

assert.assertExists(process.env.INTERCOM_ENABLED, 'INTERCOM_ENABLED must be configured');
export const INTERCOM_ENABLED = process.env.INTERCOM_ENABLED;

assert.assertExists(
  process.env.JOBMA_TOOLKIT_URL,
  'JOBMA_TOOLKIT_URL must be configured for this to work'
);
export const JOBMA_TOOLKIT_URL = process.env.JOBMA_TOOLKIT_URL;

assert.assertExists(process.env.LOGGER_ACCESS_TOKEN, 'LOGGER_ACCESS_TOKEN must be configured');
export const LOGGER_ACCESS_TOKEN = process.env.LOGGER_ACCESS_TOKEN;

assert.assertExists(process.env.LOGGER_ENABLED, 'LOGGER_ENABLED must be configured');
export const LOGGER_ENABLED: boolean = process.env.LOGGER_ENABLED as any;

assert.assertExists(process.env.LOGGER_ENV, 'LOGGER_ENV must be configured');
export const LOGGER_ENV = process.env.LOGGER_ENV;

assert.assertExists(process.env.LOGGER_ENVIRONMENT, 'LOGGER_ENVIRONMENT must be configured');
export const LOGGER_ENVIRONMENT = process.env.LOGGER_ENVIRONMENT;

assert.assertExists(process.env.LOGGER_IGNORE_LIST, 'LOGGER_IGNORE_LIST must be configured');
export const LOGGER_IGNORE_LIST = process.env.LOGGER_IGNORE_LIST;

assert.assertExists(
  process.env.NEWTON_TOOLKIT_URL,
  'NEWTON_TOOLKIT_URL must be configured for this to work'
);
export const NEWTON_TOOLKIT_URL = process.env.NEWTON_TOOLKIT_URL;

assert.assertExists(process.env.PROFILE_URL, 'PROFILE_URL must be configured for this to work');
export const PROFILE_URL = process.env.PROFILE_URL;

assert.assertExists(
  process.env.RESEARCH_TOOLKIT_URL,
  'RESEARCH_TOOLKIT_URL must be configured for this to work'
);
export const RESEARCH_TOOLKIT_URL = process.env.RESEARCH_TOOLKIT_URL;

assert.assertExists(
  process.env.SELECTION_PRODUCT_NAME,
  'SELECTION_PRODUCT_NAME must be configured'
);
export const SELECTION_PRODUCT_NAME = process.env.SELECTION_PRODUCT_NAME;

assert.assertExists(process.env.SHARED_LOGO_VARIANT, 'SHARED_LOGO_VARIANT must be configured');
export const SHARED_LOGO_VARIANT = process.env.SHARED_LOGO_VARIANT;

assert.assertExists(process.env.WEBUI_PRIVACY_URL, 'WEBUI_PRIVACY_URL must be configured');
export const WEBUI_PRIVACY_URL = process.env.WEBUI_PRIVACY_URL;

assert.assertExists(process.env.WONSCORE_URL, 'WONSCORE_URL must be configured for this to work');
export const WONSCORE_URL = process.env.WONSCORE_URL;

assert.assertExists(
  process.env.WONSCORE_TOOLKIT_URL,
  'WONSCORE_TOOLKIT_URL must be configured for this to work'
);
export const WONSCORE_TOOLKIT_URL = process.env.WONSCORE_TOOLKIT_URL;
