import * as React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {RouteChildrenProps, useLocation} from 'react-router-dom';
import {HttpError} from 'wnd-util';

import {Spinner} from '>shared/components/spinner';
import {vr4, vr8} from '>shared/styles/mixins/verticalRhythm';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';

import {authApi} from '>root/apis';
import {findPathFromLabel, RouteLabel} from '>root/routes/routes';
import {rollbarLogger} from '>lib/logger';
import {pageChangeEffect} from '>lib/sideEffects';
import {CardHeader, FlexCard, Logo} from '>components/flexCard';
import {ExistingUserData, UserCreationForm} from '>components/userCreation/userCreationForm';
import {HelperText} from '>shared/components/form/helpers/HelperText';

import {CenteredPageContents, LightParagraphText} from './page.styles';
import {WEBUI_PRIVACY_URL} from '>root/env';

export const CreateUserPage: React.FC<RouteChildrenProps> = () => {
  const {t} = useTranslation('userCreation');
  React.useEffect(pageChangeEffect(t('userCreation:pageTitle')));

  const urlSearchParams = new URLSearchParams(useLocation().search);

  const invitationCode = urlSearchParams.get('code');
  const firstName = urlSearchParams.get('firstName') ?? undefined;
  const lastName = urlSearchParams.get('lastName') ?? undefined;
  const jobTitle = urlSearchParams.get('jobTitle') ?? undefined;
  const redirect = urlSearchParams.get('url') ?? undefined;

  console.log('redirect', redirect);

  const [userData] = React.useState<ExistingUserData>({firstName, lastName, jobTitle});
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (invitationCode) {
      setIsLoading(true);

      const isUserVerified = async () => {
        try {
          const verifiedResult = await authApi.isUserVerified({invitationCode});
          const isVerified = verifiedResult.data.verified;

          if (isVerified) {
            window.location.replace(findPathFromLabel(RouteLabel.Login));
          } else {
            setIsLoading(false);
          }
        } catch (err) {
          window.location.replace(findPathFromLabel(RouteLabel.Error));
          rollbarLogger.error(err as Error);
        }
      };

      isUserVerified();
    }
  }, [invitationCode]);

  const onSubmit = React.useCallback(
    async (data) => {
      try {
        const response = await authApi.updateInvitedUser(
          userData.firstName || data.firstName,
          userData.lastName || data.lastName,
          userData.jobTitle || data.jobTitle,
          data.newPassword,
          invitationCode,
          redirect
        );

        window.location.replace(response.data.redirect);
      } catch (error) {
        setErrorMessage(t('userCreation:genericErrorMessage'));
      }
    },
    [userData]
  );

  return (
    <CenteredPageContents>
      {isLoading ? (
        <Spinner />
      ) : (
        <FlexCard>
          <CardHeader css={vr8}>
            <Logo src={wonscoreHeaderLogoMobile} />
            <h1 css={[displayXsSemibold, vr4]}>Wonderlic</h1>
            {errorMessage ? (
              <InfoBanner bannerType={BannerType.Error} message={errorMessage} />
            ) : (
              <LightParagraphText>{t('userCreation:subTitle')}</LightParagraphText>
            )}
          </CardHeader>
          <UserCreationForm
            buttonText={t('userCreation:primaryButtonText')}
            setErrorMessage={setErrorMessage}
            onSubmit={onSubmit}
            userData={userData}
          />
          <HelperText>
            <Trans ns="common" i18nKey="privacy.consentMessage">
              By submitting this information, you agree to our
              <a href={WEBUI_PRIVACY_URL} target="_blank">
                Privacy Policy
              </a>
              .
            </Trans>
          </HelperText>
        </FlexCard>
      )}
    </CenteredPageContents>
  );
};
