import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { wonscoreHeaderLogoMobile } from '>shared/components/icon/icons';
import { displayXsSemibold } from '>shared/components/typography/designSystemTypography';
import { vr4, vr8 } from '>shared/styles/mixins/verticalRhythm';
import { BannerType, InfoBanner } from '>shared/components/banners/infoBanner';

import { authApi } from '>root/apis';
import { BILLING_URL, WEBUI_PRIVACY_URL } from '>root/env';
import { CenteredPageContents, LightParagraphText } from './page.styles';
import { CardHeader, FlexCard, Logo } from '>components/flexCard';
import { PasswordCreationForm } from '>components/passwordCreation/passwordCreationForm';
import { pageChangeEffect } from '>lib/sideEffects';
import { rollbarLogger } from '>lib/logger';
import { HelperText } from '>shared/components/form/helpers/HelperText';

export const CreatePasswordPage: React.FC<RouteChildrenProps> = () => {
  const { t } = useTranslation('passwordCreation');
  React.useEffect(pageChangeEffect(t('passwordCreation:pageTitle')));

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = React.useCallback(async (data) => {
    try {
      await authApi.createPassword(data.newPassword);

      window.location.replace(`${BILLING_URL}/account-management/productPicker`);
    } catch (error) {
      setErrorMessage(t('common:error.default.message'));
      rollbarLogger.error(error as Error);
    }
  }, []);

  return (
    <CenteredPageContents>
      <FlexCard>
        <CardHeader css={vr8}>
          <Logo src={wonscoreHeaderLogoMobile} />
          <h1 css={[displayXsSemibold, vr4]}>Wonderlic</h1>
          {errorMessage ? (
            <InfoBanner bannerType={BannerType.Error} message={errorMessage} />
          ) : (
            <LightParagraphText>{t('passwordCreation:instructions')}.</LightParagraphText>
          )}
        </CardHeader>
        <PasswordCreationForm
          buttonText={t('passwordCreation:primaryButtonText')}
          setErrorMessage={setErrorMessage}
          onSubmit={onSubmit}
        />
        <HelperText>
          <Trans ns="common" i18nKey="privacy.consentMessage">
            By submitting this information, you agree to our
            <a href={WEBUI_PRIVACY_URL} target="_blank">
              Privacy Policy
            </a>.
          </Trans>
        </HelperText>
      </FlexCard>
    </CenteredPageContents>
  );
};
